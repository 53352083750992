@import 'functions';

@mixin formWrapper {
	width: 100%;
	padding: rem(24px);
	margin-top: rem(2px);
	background-color: var(--bg);
}

@mixin itemsContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 1.5rem;
	gap: rem(8px);
	overflow: hidden;
	background-color: var(--border-install-update);
}

@mixin signButton {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: rem(10px);
	width: 100%;
	height: rem(44px);
	padding: rem(10px);
	border: rem(2px) solid var(--primary);
	border-radius: 0 rem(4px) rem(4px) 0;
	background-color: var(--bg-dark);
	color: var(--primary);
	font-size: rem(16px);
	cursor: pointer;
	&:hover {
		opacity: 0.7;
	}
}

@mixin divider {
	width: 100%;
	height: rem(1px);
	background-color: var(--border-popup-inside);
	border: none;
	margin: 0;
}

@mixin error {
	margin: rem(10px) 0 rem(20px);
	font-style: normal;
	font-weight: 600;
	font-size: rem(14px);
	color: var(--red-warn);
}

@mixin scrollbar {
	&::-webkit-scrollbar {
		width: rem(8px);
		margin-left: rem(10px);
	}
	&::-webkit-scrollbar-thumb {
		background: var(--bg);
		margin-left: rem(10px);
		border-radius: 20px;
		&:hover {
			background: var(--border-active);
		}
	}
}

@mixin card-dots {
	color: var(--text-normal);
	border: 0;
	background: transparent;
	&:not(:disabled) {
		cursor: pointer;
	}
	> svg,
	> img {
		width: 1.5rem;
		height: 1.5rem;
	}
}

@mixin borders-effect {
	border: 1px solid var(--border-popup-inside);
	transition: box-shadow 0.2s, border-color 0.2s;

	&:hover {
		border-color: transparent;
		box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
			0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
	}
}
