@use 'mixins';
@import 'functions';

.container {
	padding: 0 0 0.5rem;

	.intro {
		padding: 2rem 0 1rem 0;
		gap: 1.5rem;
	}
	h1.title {
		color: var(--text-subtle);
		font-size: 2.5rem;
		font-weight: 600;
	}
	.tag {
		color: var(--text-normal, var(--text-normal, #89969f));
		font-size: 1.28571rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		border-radius: 50px;
		background: var(--bg-dark, #1c2225);
		padding: 0.5rem 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: rem(10px);
	}
	.imgContainer {
		width: 100%;
		aspect-ratio: 3/1;
		border-radius: rem(24px);
		background: var(--bg-light);

		& > img {
			border-radius: 12px;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	h2.date {
		padding: 0.75rem 0 0;
		font-size: 1rem;
		font-weight: 500;
		color: var(--text-normal);
	}
	.meta {
		display: flex;
		gap: 1.5rem;
		align-items: center;
		white-space: nowrap;
	}
	.description {
		padding: 0 0 2rem;
		font-size: 1rem;
		font-weight: 400;
		color: var(--text-normal);
	}

	.backButton {
		display: flex;
		align-items: center;
		gap: rem(5px);
		cursor: pointer;
		color: var(--text-primary);
		font-size: rem(16px);
		font-weight: 600;
		&:hover {
			color: var(--primary-dark);
		}
	}
}
