@import 'functions';
@import 'breakpoints.module.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: rem(32px);
}

.title {
	color: var(--text-normal);
	font-size: rem(32px);
	font-weight: 700;
}

.templatesSection {
	display: flex;
	flex-direction: column;
	gap: rem(32px);
}
.titleRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	.subTitle {
		flex-grow: 1;
		font-weight: 600;
		font-size: rem(20px);
		margin: 0;
		color: var(--text-normal);
	}
}

.allTemplatesContainer {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: rem(10px);
	gap: 16px;
	width: 100%;
}
.cardWrapper {
	width: 100%;
	@include min-md {
		width: calc((100% - rem(32px)) / 3);
	}
}

.noElementsInfo {
	display: flex;
	align-items: center;
	gap: rem(10px);
	color: var(--text-normal);
	font-size: rem(20px);
	font-weight: normal;
}

.paginationContainer {
	display: flex;
	justify-content: center;
	margin-top: rem(24px);
}
