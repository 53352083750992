@import 'functions';

.redDot {
	width: rem(12px);
	height: rem(12px);
	background-color: var(--add-red);
	border-radius: 50%;
	z-index: 1;
	position: absolute;
	display: inline-block;
}
