@import './styles/search';
@import './styles/moreMenu';
@import './styles/markdown';

.mc-table {
	color: var(--text-subtitle, #e7e7e7);
	font-weight: 600;
	border-collapse: separate;
	border-spacing: 0;
	width: 100%;
	margin-top: 1rem;

	thead > tr {
		border-bottom: 1px solid var(--borders-popups-inside, #333d43);
	}

	td,
	th {
		padding: 1rem 0.75rem;
		&:first-child,
		&:last-child {
			padding-left: 0;
		}
	}
	th {
		font-size: 1rem;
		font-weight: 600;
		text-align: left;
		border-bottom: 1px solid var(--borders-popups-inside, #333d43);
	}

	td {
		font-size: 0.875rem;
		color: var(--text-normal, #89969f);
	}

	td.mc-textCell {
		word-break: break-all;
	}

	td.mc-actionsCell {
		.mc-cellWrapper {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	tr:not(:last-child) {
		td {
			border-bottom: 1px solid var(--borders-popups-inside, #333d43);
			&:first-child,
			&:last-child {
				border-bottom-color: transparent;
			}
		}
	}
}

.mc-divider {
	border: 0;
	border-bottom: 1px solid var(--borders-popups-inside, #333d43);
	width: 100%;
}

.mc-iconButton {
	background: transparent;
	border: 0;
	color: var(--text-normal, #89969f);
	cursor: pointer;
	&:hover {
		color: var(--text-subtitle, #e7e7e7);
	}
	padding: 0;
	margin: 0.25rem 0.5rem;
	position: relative;
	&.mc-marked::after {
		content: ' ';
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50%;
		background-color: var(--add-red);
		position: absolute;
		top: 0;
		right: 0;
	}
	&button.mc-marked::after {
		right: 6px;
	}
	&.mc-disabled,
	&:disabled {
		color: var(--text-disabled);
		cursor: default;
	}
}

.mc-button {
	display: flex;
	padding: 0.5rem 2rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;

	font-family: Inter;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	border-radius: 3.125rem;
	border: 0.1rem solid var(--buttons-secondary, #6c7b84);
	background: transparent;
	color: var(--text-normal);

	transition-duration: 0.1s;
	transition-timing-function: ease-in-out;
	transition-property: background, border-color, color;

	user-select: none;

	&.mc-centered {
		align-self: center;
	}

	&.mc-primary {
		--button-fill-normal: var(--button-bg, #068298);
		--button-fill-hover: var(--bg-active, #0b3842);
	}
	&.mc-primary,
	&.mc-danger {
		color: var(--button-text);
		background: var(--button-fill-normal);
		border-color: var(--button-fill-normal);
		&:not(:disabled, .mc-disabled) {
			&:hover,
			&.mc-hover,
			&:focus-visible,
			&.mc-focused {
				background: var(--button-fill-hover);
				color: var(--button-text);
			}
			&:hover,
			&.mc-hover {
				border-color: var(--button-fill-hover);
			}
		}

		&:disabled,
		&.mc-disabled {
			background: var(--element-disabled, #31383b);
		}
	}

	&.mc-danger {
		--button-fill-normal: var(--button-secondary);
		--button-fill-hover: var(--add-red);
	}

	&:not(:disabled) {
		&:hover,
		&.mc-hover,
		&:focus-visible,
		&.mc-focused {
			background: var(--bg-dark, #1c2225);
			color: var(--text-subtitle);
		}
		cursor: pointer;
	}

	&:disabled,
	&.mc-disabled {
		cursor: default;
		color: var(--text-disabled);
		border-color: var(--element-disabled, #31383b);
	}

	&:focus-visible,
	&.mc-focused {
		border-color: var(--primary);
		outline: 0.1rem solid var(--primary);
	}
}

.mc-nowrap {
	white-space: nowrap;
}

a.mc-button {
	&.mc-secondary {
		&:hover,
		&.mc-hover {
			color: var(--text-subtitle);
		}
		&.mc-disabled {
			color: var(--text-normal);
		}
	}
}

.mc-buttonsCol {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	row-gap: 1rem;

	> .mc-button {
		margin-left: 0;
		margin-right: 0;
	}
}

.mc-buttonsRow {
	display: flex;
	flex-direction: row;
	justify-content: center;

	align-items: stretch;
	column-gap: 1rem;

	> .mc-button {
		margin-left: 0;
		margin-right: 0;
	}
}

.mc-dialogBackdrop {
	&.mc-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 998;
		display: flex;
		align-items: center;
		justify-content: center;
		backdrop-filter: blur(2px) brightness(80%);
	}

	&:not(.mc-modal) {
		.mc-dialog {
			position: relative;
		}
	}

	.mc-dialog {
		width: 70rem;
		min-height: 12rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 0;
		border: 0.1rem solid var(--border-popup);
		box-shadow: 0 0.125rem 2rem var(--bg-60);
		border-radius: 1.5rem;
		background-color: var(--bg);
		color: var(--text-normal);

		.mc-dialogContent {
			display: flex;
			flex-direction: column;
			padding-inline: 1rem;
			align-self: flex-start;
			min-width: 100%;

			.mc-dialogBody {
				padding: 0rem 1.5rem;
			}
		}

		.mc-dialogTitle {
			color: var(--text-primary);
			font-size: 1.125rem;
			text-align: left;
		}

		.mc-dialogHeader {
			padding: 1.5rem 1.5rem 1rem 1.5rem;
			display: flex;
			justify-content: flex-start;
			width: 100%;
		}

		.mc-dialogFooter {
			padding: 1.5rem;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			@media (max-width: 576px) {
				padding: 0.5rem 0.5rem 1.5rem 0.5rem;
			}
		}
		@media (max-width: 1024px) {
			width: 100% !important;
			overflow-y: auto;
			justify-content: flex-start;
		}
	}
}
.mc-disabled {
	pointer-events: none;
}

.mc-formField {
	width: 100%;
	display: flex;
	flex-direction: column;

	.mc-fieldLabel {
		user-select: none;
		margin-bottom: 0.25rem;
		font-size: 0.875rem;
		color: var(--text-normal, #89969f);
	}

	.mc-fieldError {
		color: var(--additional-red, #f04b4b);
		text-align: right;
		font-family: Inter;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-top: 0.5rem;
		height: 1rem;
	}

	&.mc-hasError {
		.mc-textBox {
			border-color: var(--additional-red, #f04b4b);
		}
	}

	.mc-textBox {
		flex: 1;
		color: var(--text-normal, #89969f);
		font-family: Inter;
		font-size: 1rem;
		font-weight: 600;
		line-height: normal;
		display: flex;
		padding: 0.75rem 1rem;
		border-radius: 1.5rem;
		background: var(--bg);
		border: 1.5px solid var(--border-popup, #6c7b84);
		&:read-only {
			border-color: var(--border-popup-inside);
		}

		&:disabled {
			color: var(--text-disabled);
		}

		&::placeholder {
			color: var(--text-normal);
			opacity: 0.4;
		}
	}
}

.mc-checkBox {
	input[type='checkbox'] {
		display: none;
	}
}
