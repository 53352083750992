@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';

.wrapper {
	background-color: var(--surface);
}

.container {
	padding: 0;
}

.header {
	font-size: rem(32px);
	font-weight: 700;
	color: var(--text-normal);
	margin-bottom: rem(16px);
}
