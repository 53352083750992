@import 'functions';
@import 'breakpoints.module.scss';

.wrapper {
	display: inline-flex;
	flex-direction: column;
}

.filterField {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: rem(11px);
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: rem(12px);
	border-radius: rem(50px);
	height: rem(46px);
	border: none;
	color: var(--text-normal);
	background: var(--bg-dark);
	cursor: pointer;
}

.filterFieldDropDown {
	margin-top: 0.7rem;
	position: absolute;
	border: rem(1.5) solid var(--border-popup);
	background-color: var(--bg);
	box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
	border-radius: rem(16px);
	overflow: hidden;
	text-wrap: nowrap;
	z-index: 10;
	min-width: rem(283px);
}

.filterFieldDropItem {
	font-size: rem(18px);
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	padding: rem(12px);
	color: var(--text-normal);
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: rem(32px);
	&:hover {
		background-color: var(--bg-hover);
		color: var(--primary);
	}
}

.filterFieldDropItemDisabled {
	font-size: rem(18px);
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	padding: rem(12px);
	color: var(--text-disabled);
	background-color: var(--bg-dark);
	cursor: not-allowed;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: rem(32px);
}

.filterFieldCategoryLabel {
	font-size: rem(14px);
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	padding: rem(12px);
	color: var(--text-primary);
	cursor: default;
	user-select: none;
}

.filterFieldCategory:not(:last-child) {
	border-bottom: rem(1.5) solid var(--border-popup);
}

.filterTag {
	border-radius: rem(24px);
	border: 1px solid var(--button-secondary);
	display: inline-flex;
	flex-direction: row;
	text-wrap: nowrap;
	align-items: center;
	gap: rem(6px);
	user-select: none;
	cursor: pointer;
	justify-content: center;
	height: rem(32px);
	padding: 0 rem(16px);
}

.filterTagContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: rem(8px);
}
