.modal {
	:global(.ant-modal-content) {
		border-radius: 0.5rem;
		box-shadow: 0 0.25rem 0.75rem var(--bg-40);
	}

	:global(.ant-modal-header) {
		border-bottom: 1px solid var(--border-popup-inside);
		padding: 1rem 1.5rem;
		background-color: var(--bg);
	}

	:global(.ant-modal-body) {
		padding: 1.5rem;
		max-height: 70vh;
		overflow-y: auto !important;

		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			background: var(--bg-light);
		}

		&::-webkit-scrollbar-thumb {
			background: var(--text-disabled);
			border-radius: 0.25rem;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: var(--text-normal);
		}
	}
}

.scrollableContent {
	padding-right: 1rem;
}

.section {
	margin-bottom: 1.5rem;
}

.section p {
	margin-bottom: 0.75rem;
}

.section p:last-child {
	margin-bottom: 0;
}

.section strong {
	font-size: 1.125rem;
	font-weight: 600;
	color: var(--text-subtle);
	display: block;
	margin-bottom: 12px;
}

.section b {
	font-size: 1.125rem;
	font-weight: 600;
	color: var(--text-subtle);
	margin-right: '1rem';
}

.paragraphBullet {
	display: inline;
	margin-left: 0.5rem;
}

.titleHeader {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
}

.section p:not(:first-child) {
	font-size: 0.875rem;
	line-height: 1.6;
	color: var(--text-normal);
	max-width: 70ch;
}
