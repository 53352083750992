@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';

.wrapper {
	background-color: var(--surface);
}

.container {
	padding: 0;
}

.containerNews {
	max-width: var(--max-width);
	color: var(--text-normal);
	display: flex;
	flex-direction: column;
	gap: rem(32px);

	.title {
		font-size: rem(32px);
		font-weight: 700;
		margin-bottom: rem(16px);
	}
	.newsArticles {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: rem(16px);
		.newsArticle {
			width: calc(33% - 1rem);
			flex-grow: 1;
			background-color: var(--bg);
			@include max-md {
				width: 100%;
			}
			@include min-md {
				&:last-child {
					width: calc(33% - 1rem);
					flex-grow: 0;
				}
			}
			img {
				max-height: rem(105px);
				object-fit: cover;
			}

			button {
				margin-top: rem(16px);
			}
		}
	}
}
