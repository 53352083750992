@import 'functions';

.disabled {
	color: var(--text-disabled);
	cursor: default;
}

.cardTitle {
	align-items: center;
	display: flex;
	gap: rem(8px);
	justify-content: flex-start;
	padding: rem(12px) 0;
}

.cardInfo {
	font-size: 11px;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.extraButton {
	width: 22px;
	height: 22px;
	align-content: center;
	align-items: center;
	display: flex;
	justify-content: center;
}

.extraOpenWith {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
}
