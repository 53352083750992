@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';
.cardWrapper {
	width: 100%;
	margin-bottom: 2rem;

	.container {
		@include mixins.borders-effect;
	}

	@include min-md {
		width: calc((100% - rem(32px)) / 3);
		display: flex;
	}
}

.recentFileCardWrapper {
	// Gap is 1 rem, so remove 3 gaps from the width before dividing
	width: calc((100% - 3rem) / 4);
}

.enginesContainer {
	width: 100%;
	padding: rem(24px);
	background-color: var(--bg-darker);
	border-radius: rem(24px);
	display: flex;
	align-items: center;
	gap: rem(24px);

	@include max-sm {
		flex-direction: column;
	}
}

.includedAppsContainer {
	@include mixins.itemsContainer;
}

.chooseEngineContainer {
	display: flex;
	gap: rem(10px);
	margin-bottom: rem(8px);
}

.chooseEngine {
	display: inline-block;
	width: fit-content;
	padding-bottom: rem(5px);
	color: var(--text-primary);
	font-size: rem(18px);
	font-weight: 600;
	border-bottom: rem(2px) solid var(--text-primary);
}

.allRecentFilesContainer {
	display: flex;
	gap: rem(16px);
	flex-wrap: wrap;
	width: 100%;
}

.cardDots {
	@include mixins.card-dots;
	margin-left: rem(-16px);
}

.noElementsInfo {
	display: flex;
	align-items: center;
	gap: rem(10px);
	color: var(--text-normal);
	font-size: rem(16px);
	font-weight: normal;
}

.appsToTryCarousel {
	:global(.slick-track) {
		display: flex !important;
	}

	:global(.slick-slide) {
		height: auto;
		> div {
			height: 100%;
		}
	}
}

.sameHeightSlide {
	height: 100%;
	:global(.card-container) {
		height: 100%;
		> div,
		> div > div {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
		:global(.description-container) {
			margin-bottom: 0;
		}
	}
}
