@import 'functions';

.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--surface);
}

.containerNotFoundElement {
	@extend .container;
	height: 100%;
	width: 100%;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.image {
	width: rem(258px);
	height: rem(314px);
	background-image: url('../images/404image.png');
}

.mainText {
	font-size: rem(32px);
	font-weight: 600;
	color: var(--text-primary);
	margin: rem(32px) 0 rem(16px);
}

.subtleText {
	font-size: rem(13px);
	font-weight: 600;
	color: var(--text-normal);
	margin: 0 0 rem(32px);
}
