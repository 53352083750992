@import 'functions';
@import 'breakpoints.module.scss';

.container {
	display: flex;
	flex-direction: column;
	gap: rem(32px);

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: rem(26px);
		@include max-xl {
			flex-wrap: wrap;
			justify-content: flex-end;
		}
		.avatarImage {
			border-radius: rem(5px);
			width: rem(37px);
			height: rem(37px);
			object-fit: cover;
			object-position: center;
		}
		.btnContainer {
			display: flex;
			align-items: center;
			gap: rem(24px);
			.version {
				display: flex;
				align-items: center;
				gap: rem(10px);
				width: fit-content;
				height: rem(38px);
				padding: rem(8px) rem(32px);
				border-radius: rem(50px);
				background-color: var(--bg-dark);
				font-weight: 600;
				font-size: rem(18px);
				color: var(--text-normal);
			}
		}
		.title {
			color: var(--text-subtle);
			font-size: rem(32px);
			font-weight: 600;
			margin: 0;
			flex-grow: 1;
			word-break: break-all;
			@include max-xl {
				font-size: rem(30px);
			}
		}
		.templateTitle {
			@extend .title;
			color: var(--text-normal);
			@include max-xl {
				font-size: rem(32px);
			}
		}
	}

	.imgContainer {
		width: 100%;
		aspect-ratio: 3/1;
		max-height: 22rem;
		border-radius: rem(24px);
		background: var(--bg-light);

		& > img {
			border-radius: rem(24px);
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	.appLogos {
		color: var(--text-normal);
		display: flex;
		align-items: center;
		gap: 2rem;
		height: 4rem;
		.appLogo {
			display: flex;
			align-items: center;
			gap: 1rem;
			font-size: 1.5rem;
			img {
				height: 4rem;
			}
		}
	}

	.descriptionContainer,
	.releaseNotesContainer {
		display: flex;
		flex-direction: column;
		gap: rem(16px);
		color: var(--text-normal);
		.descriptionTitle,
		.releaseNotesTitle {
			margin: 0;
			font-weight: 600;
			font-size: rem(24px);
			color: var(--text-normal);
		}
		.releaseNotesList {
			padding-left: rem(24px);
			margin: 0;
		}
		h4.description {
			margin: 0;
			font-size: rem(16px);
			font-weight: 600;
			line-height: rem(19px);
			text-align: justify;
			color: var(--text-normal);
			white-space: pre-wrap;
			word-break: break-word;
		}
	}

	h4.backButton {
		margin: 0;
		display: flex;
		align-items: center;
		gap: rem(5px);
		cursor: pointer;
		color: var(--text-primary);
		font-size: rem(16px);
		font-weight: 600;
		&:hover {
			color: var(--primary-dark);
		}
	}
}
