@use 'mixins';
@import 'functions';

.title {
	font-size: rem(20px);
	color: var(--text-normal);
}

.subscriptionItemsContainer {
	@include mixins.itemsContainer;
	margin-top: rem(16px);
}

.subscriptionItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: rem(2px);
	width: 100%;
	min-height: rem(76px);
	background-color: var(--bg);
	padding: rem(16px);
	color: var(--text-normal);
	cursor: pointer;
	&:hover {
		background-color: var(--bg-active);
		h4.titleItem,
		.textItem {
			color: var(--button-text) !important;
		}
	}
}

.titleItem {
	font-size: rem(16px);
	color: var(--text-subtle);
}

.subtitle {
	padding: rem(16px) 0;
	font-size: rem(16px);
	color: var(--text-subtle);
}

.textItem {
	font-size: rem(14px);
	color: var(--text-normal);
	display: flex;
	align-items: center;
	gap: rem(15px);
}

.actionsItem {
	display: flex;
	gap: rem(22px);
	align-items: center;
	justify-content: flex-end;
}

.iconItem {
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
}

.openedSubscriptionWrapper {
	padding: rem(16px);
	width: 100%;
	background-color: var(--bg);
}

.containersSection {
	display: flex;
	justify-content: flex-end;
}

.buttonsSection {
	display: flex;
	justify-content: space-between;
	gap: 24px;
	padding: rem(24px) 0 rem(8px) 0;
}

.statusRequestCircle {
	margin: 0.5rem;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50%;
}

.redCircle {
	position: absolute;
	right: -4px;
	top: 0;
	width: rem(8px);
	height: rem(8px);
	border-radius: 50%;
	background-color: var(--add-red);
}

.appsSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: rem(60px);
	gap: 2px;
	background: var(--bg-dark);
	color: var(--text-normal);
	font-size: rem(16px);

	.appItem {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: rem(15px);
		flex-grow: 1;
		height: rem(56px);
		border-bottom: 0.48rem solid transparent;
		border-top: 0.48rem solid transparent;
		transition-property: border-color;
		transition-duration: 100ms;
		transition-timing-function: ease-in-out;

		&:hover:not(.unavailable) {
			border-bottom-color: var(--bg-hover);
		}

		.appIcon {
			max-width: 2rem;
		}

		&.activated {
			color: var(--button-text);
			background: var(--bg-active, #0b3842);
			cursor: pointer;
		}
		&.available {
			cursor: pointer;

			&.selected {
				border-bottom-color: var(--text-primary, #01b2d0);
			}
		}
		&.unavailable {
			opacity: 0.25;
		}
		&.unlicensed {
			display: none;
			opacity: 0.05;
		}
	}

	&.deactivation .appItem.activated.selected {
		border-bottom-color: var(--add-orange);
	}
}

.dividerElements:not(:last-of-type) {
	border-bottom: 1.5px solid var(--border-popup);
	width: 100%;
}

.divider {
	border-bottom: 1.5px solid var(--border-popup);
	width: calc(100% + rem(48px));
	position: relative;
	left: rem(-1.5rem);
}

.wizardModal {
	margin: 0 auto;
	display: flex;
	width: 400px;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	border-radius: 1.5rem;
	padding: 1.5rem;
	background: var(--bg, #181d1f);
	.sectionText {
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
		h3 {
			color: var(--text-subtitle, #e7e7e7);
			text-align: center;
			font-size: 1.25rem;
			line-height: normal;
		}
		p {
			color: var(--text-normal, #89969f);
			text-align: center;
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 1rem;
		}
	}
}
