@import 'functions';
@import 'breakpoints.module.scss';

.container {
	display: flex;
	min-height: rem(228px);
	justify-content: space-between;
	gap: rem(40px);
	background-color: var(--bg);
	border-radius: rem(24px);
	padding: rem(24px);
	width: 100%;
	@include max-sm {
		flex-direction: column-reverse;
		gap: rem(24px);
	}
}
.templateContainer {
	@extend .container;
	height: rem(201px);
}
.leftContainer {
	display: flex;
	flex-direction: column;
	gap: rem(24px);
	width: 100%;

	.title {
		font-size: rem(24px);
		font-weight: 600;
		color: var(--text-normal);
		margin: 0;
	}
	.descriptionContainer {
		.description {
			font-size: rem(16px);
			color: var(--text-normal);
			line-height: rem(19px);
			margin: 0;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			white-space: pre-wrap;
			word-break: break-word;
		}
		.templateDescription {
			@extend .description;
			-webkit-line-clamp: 2;
		}
	}
}

.btnContainer {
	margin-top: auto;
	justify-content: flex-start;
	display: flex;
}

.rightContainer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	.imgContainer {
		height: 100%;
		aspect-ratio: 3/1;
		border-radius: rem(24px);
		background: var(--bg-light);

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			border-radius: rem(24px);
		}
	}

	.templateImgContainer {
		@extend .imgContainer;
		height: 90%;
	}
}
