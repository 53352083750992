@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';

.inputWrapper {
	width: 100%;
}
.input {
	width: 100%;
	height: rem(44px);
	padding-left: rem(16px);
	border-radius: rem(4px);
	font-size: rem(16px);
	color: var(--text-subtle);
	border: rem(1.5px) solid var(--border-popup);
	background-color: var(--bg);
	&:hover,
	&:focus-within {
		border: rem(1.5px) solid var(--text-normal) !important;
		background-color: var(--bg-dark);
	}
	&::placeholder {
		color: var(--text-normal);
		opacity: 0.4;
	}
}

.inputLabel {
	margin-bottom: rem(6px);
	font-size: rem(14px);
	color: var(--text-normal);
}

.inputFieldWrapper {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: rem(8px);
	.inputFieldIconWrapper {
		input {
			padding-right: 2.5rem;
		}
		position: relative;
		.inputFieldIcon {
			position: absolute;
			right: rem(11px);
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
		}
	}
}
.inputField,
.tagWrapper {
	height: rem(48px);
	padding-left: rem(20px);
	color: var(--text-normal);
	border: rem(1.5px) solid var(--border-popup);
	border-radius: 50px;
	font-size: rem(16px);
	font-weight: 600;
	width: 100%;
	background-color: transparent;
	&:focus {
		outline: none;
	}
	&:hover {
		border: rem(1.5px) solid var(--text-normal);
	}
	&::placeholder {
		color: var(--text-normal);
		opacity: 0.4;
	}
	:global {
		.ant-picker-input > input {
			color: var(--text-normal);
			font-size: rem(16px);
			font-weight: 600;
			&::placeholder {
				color: var(--text-normal);
				opacity: 0.4;
			}
		}
		.ant-picker-suffix {
			color: var(--text-normal);
		}
		.ant-picker-focused {
			box-shadow: none !important;
			border: none !important;
		}
	}
}

.noTagsMessage {
	@extend .tagWrapper;
	border: 0;
	&:hover {
		border: 0;
	}
	color: var(--text-normal);
	font-weight: 500;
	font-style: italic;
	opacity: 0.4;
}

.tagWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 0.5rem;
	border-color: var(--border-popup-inside);
	[class^='ant-tag'] {
		margin: 20px 0.25rem;
		border-radius: 1rem;
	}
}

.errorMessage {
	width: 100%;
	height: rem(24px);
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	color: var(--add-red);
	font-size: rem(14px);
	font-weight: 600;
}

:global {
	.ant-checkbox .ant-checkbox-inner {
		border-radius: 0 !important;
	}
}

.inputDateField {
	:global {
		.ant-picker-panel-container {
			background-color: var(--bg);
			border: rem(1px) solid var(--border-popup);
			box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
			border-radius: 1.5rem;
		}
		.ant-picker-cell-inner {
			color: var(--text-disabled);
		}
		.ant-picker-cell-in-view > .ant-picker-cell-inner {
			color: var(--text-normal);
		}
		.ant-picker-cell-selected > .ant-picker-cell-inner {
			color: var(--text-primary) !important;
			background-color: transparent !important;
			font-weight: 600;
		}
		.ant-picker-cell-inner::before {
			border: none !important;
		}
		.ant-picker-cell-today > .ant-picker-cell-inner {
			border: rem(1px) solid var(--text-primary) !important;
		}
		.ant-picker-today-btn {
			color: var(--text-primary);
		}
		.ant-picker-header button {
			color: var(--text-normal);
			&:hover {
				color: var(--primary);
			}
		}
		.ant-picker-content th {
			color: var(--text-normal);
		}
		.ant-picker-year-btn,
		.ant-picker-month-btn {
			font-size: rem(16px);
		}
	}
}

.inputFieldLabel {
	font-size: rem(14px);
	font-weight: 600;
	color: var(--text-normal);
}

.multiSelectField {
	width: 100%;
	min-height: rem(48px);
	display: flex;
	align-items: center;
	font-size: rem(16px);
	font-weight: 600;
	color: var(--text-normal);
	&.filterField {
		:global {
			:not(.ant-select-customize-input).ant-select-selector {
				background: var(--bg-dark);
				padding: 0.7rem 1rem;
				height: 2.875rem;
				border: 0;
			}
		}
	}
	:global {
		.rc-virtual-list-holder-inner {
			gap: 0.25rem;

			.ant-select-item {
				padding-block: 0.5rem;
			}
		}
		:not(.ant-select-customize-input).ant-select-selector {
			border: rem(1.5px) solid var(--border-popup) !important;
			border-radius: 1.5rem;
			padding-left: 1.25rem;
		}
		.ant-select-clear {
			color: var(--text-normal) !important;
			margin-right: 1.5rem;
		}
		.ant-select-selection-placeholder {
			width: 100%;
			color: var(--text-normal) !important;
		}
		.ant-select-selection-search {
			color: var(--text-normal);
		}
		.ant-select-dropdown {
			left: 0 !important;
			background-color: var(--bg);
			border: rem(1px) solid var(--border-popup);
			border-radius: 16px;
			padding: 0;
			box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
		}
		.ant-select-selector {
			width: 100%;
			min-height: rem(44px);
			.ant-select-selection-item {
				color: var(--text-normal);
				font-size: rem(16px);
				font-weight: 600;
				display: flex;
				align-items: center;
			}
		}
		.ant-select-arrow {
			color: var(--text-normal);
		}
		.ant-select-item-option-content {
			color: var(--text-normal);
			font-size: rem(16px);
			font-weight: 600;
		}
		div.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
			background-color: transparent;
			border-radius: 0;
			.ant-select-item-option-content {
				color: var(--text-primary);
				display: flex;
				justify-content: space-between;
				&::after {
					content: url('../icons/checkIcon.svg');
				}
			}
		}
		div.ant-select-item.ant-select-item-option:hover {
			background-color: var(--bg-hover);
			> * {
				color: var(--button-text);
			}
		}
		span.anticon.anticon-check {
			visibility: hidden;
		}
		.ant-select-selection-search-input {
			font-size: rem(16px);
			font-weight: 600;
		}
	}
}

.tagMultiSelectField {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: rem(10px);
	padding: 0 rem(16px);
	height: rem(38px);
	margin: rem(2px);
	font-size: rem(14px);
	font-weight: 600;
	line-height: rem(14px);
	border: none;
	background-color: var(--button-bg);
	color: var(--button-text);
	white-space: pre-wrap;
	word-break: break-all;
	border-radius: 1.5rem;
	:global {
		.ant-tag-close-icon {
			color: var(--text-subtle);
			font-size: rem(16px);
			font-weight: 600;
		}
	}
}

.labelIconName {
	display: flex;
	align-items: center;
	gap: rem(10px);
}

.inputTextareaWrapper {
	width: 100%;
	padding: rem(20px);
	min-height: rem(130px);
	border: rem(1.5px) solid var(--border-popup);
	border-radius: 1.5rem;
}

.inputTextarea {
	width: 100%;
	min-height: rem(90px);
	font-size: rem(16px);
	font-weight: 600;
	border: none;
	line-height: rem(19px);
	outline: none;
	color: var(--text-normal);
	background-color: transparent;
	resize: vertical;
	overflow: auto;
	&::placeholder {
		color: var(--text-normal);
		opacity: 0.4;
	}
}

.searchField {
	width: 100%;
	height: 100%;
	min-height: 2rem;
	padding-left: rem(14px);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: rem(6px);
	border: none;
	outline: none;
	border-radius: 50px;
	&:focus-within {
		outline: none;
	}
}
.inputSearchField {
	width: 100%;
	color: var(--text-normal);
	font-size: rem(14px);
	font-weight: 600;
	background: transparent;
	border: none;
	padding-right: rem(14px);
	&:focus-within {
		border: none;
		outline: none;
	}
	&::placeholder {
		color: var(--text-normal);
		opacity: 0.4;
	}
	&::-webkit-search-cancel-button {
		filter: contrast(0.1) brightness(1.5) grayscale(1);
	}
}

.itemContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: rem(2px);
	width: 100%;
	height: rem(66px);
	background-color: var(--bg);
	padding: rem(17px);
	font-weight: 600;
	font-size: rem(16px);
	color: var(--text-normal);
	&:hover {
		background-color: var(--bg-active) !important;
		color: white !important;
	}
	@include max-sm {
		height: initial;
		flex-direction: column;
		line-height: 1.5;
		& > div {
			width: 100% !important;
		}
	}
}
.subscriptionItem {
	@extend .itemContainer;
	min-height: rem(52px);
	padding: 0;
	&:hover {
		background-color: transparent;
	}
	margin: 0 -1rem;
	padding: 0 1rem;
	box-sizing: content-box;
}

.addButton {
	width: rem(132px);
	height: rem(46px);
	display: flex;
	justify-content: center;
	align-items: center;
	gap: rem(8px);
	padding: rem(8px) rem(32px);
	background: var(--button-bg);
	border: none;
	border-radius: 50px;
	color: var(--button-text);
	font-size: rem(18px);
	font-weight: 600;
	cursor: pointer;
	&:hover {
		background-color: var(--bg-active);
	}
	&:disabled {
		background: var(--element-disabled);
		color: var(--text-disabled);
		cursor: default;
	}
}

.saveButton {
	height: rem(40px);
	padding: rem(8px) rem(32px);
	color: var(--button-text);
	font-size: rem(18px);
	font-weight: 600;
	background-color: var(--button-bg);
	border: rem(1px) solid var(--button-bg);
	border-radius: 50px;
	&:hover {
		background-color: var(--bg-active);
		border: rem(1px) solid var(--bg-active);
	}
}

.saveButton:focus {
	outline: none;
}

.rejectButton {
	@extend .cancelButton;
	&:hover {
		background-color: var(--add-red) !important;
		border-color: var(--add-red) !important;
	}
}

.approveButton {
	@extend .cancelButton;
	&:hover {
		color: var(--text-disabled) !important;
		background-color: var(--add-green) !important;
		border-color: var(--add-green) !important;
	}
}

.cancelButton {
	@extend .saveButton;
	color: var(--text-normal);
	border: 1px solid var(--text-normal);
	background-color: transparent;
	cursor: pointer;
	&:hover {
		background: var(--bg-dark, #1c2225);
		color: var(--text-subtitle);
		border-color: var(--text-normal);
	}
	&:focus {
		outline: none;
	}
}

.disabledScreen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	background: rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
}
