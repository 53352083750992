@use 'mixins';
@import 'functions';

.thinCard-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: rem(56px);
	background-color: var(--bg-darker);
	padding: rem(17px);
	position: relative;

	&:hover {
		background-color: var(--bg-active);
		.title-container .title {
			color: var(--button-text);
		}
	}
	.title-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: var(--text-normal);
		gap: rem(12px);
		.thinCard-avatar {
			border-radius: rem(2px);
			width: rem(22px);
			height: rem(22px);
			object-fit: cover;
			object-position: center;
		}
		.title {
			color: var(--text-normal);
			font-weight: 600;
			font-size: rem(16px);
			margin: 0;
		}
		.notAvailable {
			margin: 0;
			color: var(--red-warn);
		}
		.expired {
			margin: 0;
			color: var(--primary);
		}
	}
	.buttons-container {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin: -0.5rem 0;
		.card-button {
			font-size: rem(16px);
			line-height: rem(18px);
			color: var(--primary);
			height: rem(32px);
			padding: rem(6px) rem(14px);
			background: transparent;
			border: 1px solid var(--primary);
			border-radius: 1.5rem;
		}
	}
	.card-dots {
		@include mixins.card-dots;
	}
	.detailPage {
		position: absolute;
		right: 0;
		bottom: rem(-50px);
		z-index: 9;
		border-radius: rem(16px);
		border: rem(1.5px) solid var(--border-popup, #33393c);
		background: var(--bg, #252b2e);
		box-shadow: 0px rem(2px) rem(30px) 0px rgba(0, 0, 0, 0.6);
		display: flex;
		padding: rem(12px) rem(40px) rem(12px) rem(16px);
		align-items: center;
		align-self: stretch;
		color: var(--text-normal, var(--text-normal, #89969f));
	}
}
