@import 'functions';
@import 'breakpoints.module.scss';

.section-container {
	display: flex;
	flex-direction: column;
	.section-title {
		font-weight: 600;
		font-size: rem(20px);
		margin: 0;
		padding-bottom: rem(16px);
		color: var(--text-normal);
	}
	.section-content {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		@include max-sm {
			flex-direction: column;
		}
	}
}
