.fileButtonContainer {
	position: relative;
	display: inline-block;
	width: fit-content;
	height: 40px;
}

.fileButtonInput {
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}

.fileButtonLabel {
	background-color: var(--primary-dark);
	padding: 8px 32px;
	cursor: pointer;
	color: var(--text-subtle);
	display: inline-flex;
	justify-content: center;
	border-radius: 50px;
	user-select: none;
	width: fit-content;
	height: 40px;
	font-size: 18px;
	font-family: Inter;
	font-weight: 600;
	word-wrap: break-word;
	gap: 8px;

	&.fileButtonDisabled {
		cursor: default;
		color: var(--text-disabled);
		border-color: var(--element-disabled, #31383b);
	}
}
