@use 'mixins';
@import 'functions';

.offline-modal {
	& > .ant-modal > .ant-modal-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: rem(32px) rem(24px);
		gap: rem(32px);
		border: 1px solid var(--bg-light);
		box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.6);
		border-radius: 1.5rem;
		& > .ant-modal-header {
			background-color: var(--bg);
			margin: 0;
			width: 100%;
			height: rem(42px);
			border-bottom: 1px solid hsl(202, 12%, 18%);

			& > .ant-modal-title {
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				& > p {
					font-size: rem(20px);
					color: var(--add-red);
				}
				& > svg {
					align-self: center;
				}
			}
		}
		& > .ant-modal-body {
			margin-top: rem(-4px);
			& > p {
				font-size: rem(16px);
				line-height: 19px;
				color: var(--text-normal);
			}
		}
		& > .ant-modal-footer {
			display: flex;
			flex-direction: row-reverse;
			gap: rem(24px);
			margin-top: 0;
			& > .ant-btn {
				line-height: rem(22px);
				height: rem(38px);
				border-radius: rem(50px);
				padding: rem(8px) rem(32px);
				color: var(--text-subtle);
			}
			& > .ant-btn.ant-btn-default {
				color: var(--text-normal) !important;
				border-color: var(--button-secondary);
				&:hover {
					background-color: var(--bg-active);
				}
			}
			& > .ant-btn.ant-btn-primary {
				margin: 0;
				&:disabled {
					color: var(--text-disabled);
					border-color: var(--text-disabled);
					&:hover {
						background-color: rgba(0, 0, 0, 0.04) !important;
					}
				}
				&:hover {
					background-color: var(--bg-active);
				}
			}
		}
	}
}
