@import 'functions';
@import 'breakpoints.module.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: rem(32px);
}

.title {
	color: var(--text-normal, #89969f);
	font-size: rem(20px);
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.subtitle {
	color: var(--text-normal, var(--text-normal, #89969f));
	font-feature-settings: 'clig' off, 'liga' off;
	font-size: rem(16px);
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: rem(16px) 0;
}

.faq-date {
	color: var(--text-disabled, #4b5559);
	text-align: right;
	font-size: rem(16px);
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.supportItemImg {
	width: 100%;
	max-height: rem(366px);
	object-fit: cover;
	object-position: center;
	margin: rem(24px) 0 0;
	border-radius: rem(24px);
}

.supportItemTagsRow {
	display: flex;
	gap: rem(16px);
	justify-content: space-between;
	align-items: center;
	.supportItemTags {
		display: flex;
		gap: rem(16px);
		flex-wrap: wrap;
		.supportItemTag {
			border-radius: 24px;
			background: var(--buttons-primary-dark, #068298);
			display: flex;
			height: 32px;
			padding: 0px 16px;
			justify-content: center;
			align-items: center;
			gap: 6px;
			color: var(--button-text);
			font-size: rem(14px);
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}

.page-layout {
	width: 100%;
}

.transparent-layout {
	background: transparent;
}

.subtitle {
	margin-bottom: 1rem;
}

.search-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1.5rem;
	margin-bottom: 2rem;
}

.search-field-wrapper {
	flex: auto;
	background: var(--bg, #1c2225);
	border-radius: 1.875rem;
	padding: 0.6875rem 0;
}

.collapse-container {
	background: transparent;
	overflow: hidden;
	border-radius: 0.5rem;

	.faq-text {
		display: flex;
		justify-content: space-between;

		.faq-question {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		.faq-link {
			color: var(--text-normal);
			opacity: 0.5;
			margin-left: 0.5rem;
		}
	}
}

.view-more-container {
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
}
