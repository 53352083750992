.title {
	background-color: var(--bg);
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
}

.spinner {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.buttons {
	margin-top: 1rem;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	justify-content: flex-end;
}
