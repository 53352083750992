.rotate180 {
	transform: rotate(180deg);
	transition: transform 250ms ease;
}

.rotate0 {
	transform: rotate(0);
	transition: transform 250ms ease;
}

// tooltip effect

.mc-tooltip {
	position: relative;
}

.mc-tooltip:before,
.mc-tooltip:after {
	position: absolute;
	content: '';
	opacity: 0;
	transition: all 0.4s ease;
}

.mc-tooltip:before {
	border-width: 10px 8px 0 8px;
	border-style: solid;
	border-color: var(--primary-dark) transparent transparent transparent;
	top: -15px;
	transform: translateY(20px);
}

/* Styles for the right and left position */
.mc-tooltip.tooltipRight:after {
	right: -10px;
}

.mc-tooltip.tooltipLeft:after {
	left: -10px;
}

.mc-tooltip:after {
	content: attr(data-tooltip);
	background: var(--primary-dark);
	width: 26rem;
	height: 0.75rem;
	top: -47px;
	padding: 10px;
	border-radius: 5px;
	transform: translateY(20px);
	color: var(--button-text);
	text-align: center;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.mc-tooltip:hover::before,
.mc-tooltip:hover::after {
	opacity: 1;
	transform: translateY(-2px);
}

@keyframes shake {
	0% {
		transform: rotate(2deg);
	}

	50% {
		transform: rotate(-3deg);
	}

	70% {
		transform: rotate(3deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

.tooltip-anim:hover {
	animation: shake 500ms ease-in-out forwards;
}

.lightMode .loginimage {
	background: url('./assets/images/lightmodeloginCard.jpg') no-repeat center
		center;
	background-size: cover;
}
