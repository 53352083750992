@import 'functions';
@import 'breakpoints.module.scss';
@import 'vars';

.popover-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: $profile-width;

	p {
		margin: 0;
	}
	& > :not(:last-child) {
		margin-bottom: rem(20px);
	}
	a {
		font-weight: 600;
	}
	.profile-section {
		display: flex;
		flex-direction: column;
		gap: rem(16px);
		padding-bottom: rem(20px);
		border-bottom: 1px solid var(--border-popup-inside);
		flex-grow: 1;
		.profile-avatarContainer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: rem(74px);
			.profile-avatar {
				width: rem(74px);
				height: rem(74px);
				> img {
					border-radius: 50%;
					width: rem(74px);
					height: rem(74px);
					object-fit: cover;
					object-position: center;
				}
			}
			.edit-button {
				cursor: pointer;
				transform: scale(1.2);
				&:hover path,
				&:focus path {
					stroke: var(--text-primary);
				}
			}
		}
		.profile-infoContainer {
			display: flex;
			flex-direction: column;
			gap: rem(8px);
			width: 100%;
			word-break: break-all;
			.profile-email {
				font-size: rem(12px);
			}
		}
	}
	.middle-section {
		gap: 1rem;
		padding-bottom: 1.25rem;
		border-bottom: 1px solid var(--border-popup-inside);
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		position: relative;

		.link-desktop {
			display: flex;
			align-items: center;
			gap: rem(16px);
			cursor: pointer;
			color: var(--text-normal);
			&:hover {
				color: var(--text-primary);
			}
			&.web-border {
				padding-bottom: 1.25rem;
				border-bottom: 1px solid var(--border-popup-inside);
			}
		}
		> *:hover,
		*:focus {
			color: var(--text-primary);
			path {
				stroke: var(--text-primary);
			}
		}

		.profile-preferences {
			display: flex;
			align-items: center;
			gap: rem(16px);
			cursor: pointer;
			border-bottom: 1px solid var(--border-popup-inside);
			padding-bottom: 1.25rem;
		}
		.about-button,
		.contact-button {
			display: flex;
			align-items: center;
			gap: rem(16px);
			cursor: pointer;
		}
	}
	.down-section {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		position: relative;
		.log-out {
			display: flex;
			align-items: center;
			gap: rem(16px);
			cursor: pointer;
		}
		.light-dark-toggle {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
	@include max-xs {
		width: 92%;
		margin: 0 auto;
	}
}

.profile-addon {
	position: absolute;
	right: calc(100% + 1rem);
	top: 0;
	@include max-sm {
		top: calc(100% + 1rem);
		width: 100%;
		right: 0;
	}
}
