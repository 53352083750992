@import 'functions';
@import 'breakpoints.module.scss';

.footer-wrapper {
	border-top: 1px solid var(--text-primary, #01b2d0);
	background: var(--bg-dark, #1c2225);
	z-index: 9;

	.footer-content {
		margin: 0 auto;
		max-width: var(--max-width);
		width: 100%;
		min-height: rem(48px);
		padding: rem(24px) rem(32px);
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo-container {
			cursor: pointer;
		}

		.footerMenu {
			background: none;
			border: none;
			width: 100%;
			justify-content: center;
		}

		li:after {
			display: flex;
			justify-content: center;
			margin: 0 auto;
			width: 80%;
			left: 0 !important;
		}

		.social-icons {
			display: flex;
			gap: 16px;

			svg {
				cursor: pointer;
			}
		}

		.mc-iconButton {
			&:hover {
				color: var(--text-subtitle, #e7e7e7);

				svg {
					path {
						fill: var(--primary, #e7e7e7);
					}
				}
			}
		}
	}
}
