.mc-markdown {
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: bold;
	}
	h1 {
		font-size: 1.25rem;
	}
	h2 {
		font-size: 1.125rem;
	}
	h3,
	h4,
	h5 {
		font-size: 1rem;
	}
	p {
		display: block;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: normal;
	}
	strong {
		font-weight: bold;
	}
	.youtube-embed {
		position: relative;
		width: 100%;
		margin: 1.5rem 0;

		iframe {
			border-radius: 0.5rem;
			max-width: 50rem;
			margin: 0 auto;
			display: block;
		}
	}
}
