.container {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	backdrop-filter: blur(2px) brightness(80%);
}

.modal {
	width: 758px;
	background: var(--bg);
	border-radius: 8px;
	border: 1.5px var(--bg-light) solid;
	overflow: hidden;
	box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.8);
	display: flex;
	flex-direction: column;
}

.titleBar {
	width: 100%;
	height: 40px;
	background-color: var(--bg);
	justify-content: space-between;
	flex-direction: row;
	padding: 10px;
	display: inline-flex;

	.titleContainer {
		display: flex;
		flex-direction: row;

		.titleText {
			line-height: 20px;
			color: #01b2d0;
			font-size: 11px;
		}
	}

	.closeButton {
		cursor: pointer;
	}
}

.contentBox {
	width: 100%;
	background: var(--surface);
	padding: 24px;
	display: flex;
	flex-direction: column;
}
