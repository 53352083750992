@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';

.wrapper {
	background-color: var(--surface);
}

.container {
	padding: 0;
}

.containerManageNews {
	max-width: var(--max-width);
}

.header {
	font-size: rem(32px);
	font-weight: 700;
	color: var(--text-normal);
	margin-bottom: rem(16px);
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: rem(16px);
	.backButton {
		display: flex;
		align-items: center;
		gap: rem(5px);
		cursor: pointer;
		color: var(--text-primary);
		font-size: rem(16px);
		font-weight: 600;
		&:hover {
			color: var(--primary-dark);
		}
	}
}
.boxBorderRadius {
	&:first-child {
		border-top-left-radius: 240px;
	}
}

.amountInfo {
	font-size: rem(16px);
	font-weight: 600;
	margin: rem(8px) 0;
	color: var(--text-normal);
}

.searchAddFields {
	display: flex;
	gap: rem(24px);
	flex-wrap: wrap;
	@include max-sm {
		gap: rem(16px);
	}
}

.itemsContainer {
	@include mixins.itemsContainer;
	.favoriteIcon {
		opacity: 0;
	}
	& > :hover {
		.favoriteIcon {
			opacity: 1;
		}
	}
}

.profileItem {
	display: flex;
	gap: rem(12px);
	align-items: center;

	.userNameItem {
		display: flex;
		flex-direction: column;
		gap: rem(2px);
		word-break: break-all;
	}
}

.dangerIcon {
	color: var(--text-normal);
	cursor: pointer;

	&:hover {
		color: var(--add-red);
	}
}

.warningIcon {
	color: var(--text-normal);
	cursor: pointer;

	&:hover {
		color: var(--add-orange);
	}
}

.approveIcon {
	color: var(--text-normal);
	cursor: pointer;

	&:hover {
		color: var(--add-green);
	}
}

.supportItemDescription {
	color: var(--text-normal, #89969f);
}

.supportItemDescription img {
	width: 100%;
	max-height: 29vh;
	object-fit: cover;
	object-position: center;
	border-radius: 50px;
	margin-block: 24px;
}

.tagsWrapper {
	margin-block: 24px;
	display: flex;
	justify-content: space-between;
}
.tagsButtons {
	display: flex;
	gap: 16px;
}
.tagButton {
	display: flex;
	height: 32px;
	padding: 0px 16px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border-radius: 24px;
	background: var(--primary-dark, #068298);
	color: var(--text-subtitle, #e7e7e7);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.textItem {
	display: flex;
	justify-content: flex-start;
	word-break: break-all;
	align-items: center;
}

.statusItem {
	display: flex;
	gap: rem(12px);
	justify-content: flex-start;
	align-items: center;
}

.statusCircle {
	width: rem(10px);
	height: rem(10px);
	border-radius: 50%;
}

.buttonsItem {
	display: flex;
	gap: rem(22px);
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;
	@include max-sm {
		justify-content: center;
		margin-top: rem(16px);
	}
	.favoriteIcon {
		&:hover {
			color: var(--text-primary);
			& svg {
				fill: currentColor;
			}
		}
	}
	.favoriteIconClicked {
		opacity: 1;
		color: var(--text-primary);
		& svg {
			fill: currentColor;
		}
	}
}

.scrollbar {
	@include mixins.scrollbar;
	overflow-y: auto;
	max-height: rem(620px);
	padding-right: rem(10px);
	display: flex;
	flex-direction: column;
}

.noElementsInfo {
	display: flex;
	align-items: center;
	gap: rem(10px);
	color: var(--text-normal);
	font-size: rem(20px);
	font-weight: normal;
}

.searchFieldWrapper {
	height: rem(46px);
	background-color: var(--bg);
	border-radius: rem(50px);
}

.listTitle {
	font-size: rem(20px);
	font-weight: 600;
	color: var(--text-normal);
	margin: rem(32px) 0 rem(16px);
}

.cardWrapper {
	width: 100%;
	.container {
		@include mixins.borders-effect;
	}
	@include min-md {
		width: calc((100% - rem(32px)) / 3);
		display: flex;
	}
}

.cardContainer {
	display: flex;
	flex-direction: column;
	background-color: var(--bg);
	border-radius: 1.5rem;
	padding: 1.5rem;
	width: 100%;
	@include mixins.borders-effect;

	button:disabled {
		cursor: default;
	}
}

.cardImgContainer {
	width: 100%;
	aspect-ratio: 3/1;
	overflow: hidden;
	background-color: var(--bg-light);
	border-radius: 12px;

	.cardImage {
		width: 100%;
		height: 100%;
		border-radius: 12px;
		object-fit: cover;
		object-position: center;
	}
}

.cardDescriptContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 1.5rem 0 0;

	.cardDescriptTitle {
		font-size: rem(16px);
		margin: 0;
		word-break: break-all;
	}

	.description {
		width: 100%;
		max-height: rem(96px);
		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
		overflow: hidden;
		white-space: pre-wrap;
		word-break: break-word;
		font-size: rem(13px);
		font-weight: 600;
		line-height: rem(16px);
		margin: 0;
		margin-bottom: 24px;
	}

	.descriptionLessRows {
		@extend .description;
		height: auto;
		-webkit-line-clamp: 4;
	}
}

.cardBtnContainer {
	margin-top: auto;
	display: flex;
	gap: 22px;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;

	.extraNewsIcon {
		& svg {
			fill: var(--add-orange);
			color: var(--add-orange);
		}
	}
	.starIcon:hover {
		color: var(--add-orange);

		& svg {
			fill: var(--add-orange);
			color: var(--add-orange);
		}
	}
}

.appAvatar {
	border-radius: rem(6px);
	width: rem(32px);
	height: rem(32px);
	object-fit: cover;
	object-position: center;
}

.userAvatar {
	border-radius: 50%;
	width: rem(38px);
	height: rem(38px);
	object-fit: cover;
	object-position: center;
}

.editFormWrapper {
	z-index: 99;
	width: 100%;
	position: absolute;
	top: rem(-2px);
}

.paginationContainer {
	display: flex;
	justify-content: center;
	margin-top: rem(24px);
}
