@import 'functions';
@import 'breakpoints.module.scss';
@import 'vars';

.header-content {
	margin: 0 auto;
	background: var(--bg);
	max-width: var(--max-width);
	width: 100%;
	min-height: rem(48px);
	padding: rem(8px) rem(32px);
	display: flex;
	align-items: center;
}

.header-middle-section {
	display: flex;
	align-items: center;
	flex: 1;
	justify-content: flex-end;
	position: relative;

	.headerMenu {
		font-weight: 600;
		font-size: rem(16px);
		line-height: rem(50px) !important;
		border-bottom: none !important;
		background: var(--bg);
		flex: auto;
		height: rem(48px);
		width: 100%;
		color: var(--text-primary);

		.ant-menu-submenu-title {
			color: var(--text-normal);
		}
		.ant-menu-item {
			max-width: rem(120px);
			width: 100%;
			padding: 0 rem(10px);
			text-align: center;
			color: var(--text-normal) !important;
			top: 0 !important;

			&::after {
				inset-inline: 0 !important;
				border-bottom: rem(3px) solid transparent !important;
			}
		}

		.ant-menu-item-disabled {
			opacity: 0.5 !important;
		}

		.ant-menu-submenu {
			padding-inline: rem(30px);

			&::after {
				inset-inline: 0 !important;
				border-bottom: rem(3px) solid transparent !important;
			}

			&-selected {
				&::after {
					border-bottom: rem(3px) solid var(--text-primary) !important;
				}
			}

			&-active {
				&::after {
					border-bottom: rem(3px) solid var(--text-primary) !important;
				}
			}
		}

		.ant-menu-item-selected {
			color: var(--text-primary) !important;

			&::after {
				border-bottom: rem(3px) solid var(--text-primary) !important;
			}
		}

		.ant-menu-item-active {
			color: var(--text-primary) !important;
		}
	}
	.empty-space {
		min-width: var(--search-width-close);
		@include max-xs {
			display: none;
		}
	}
}
.ant-menu-submenu-popup.ant-menu-submenu {
	background: var(--bg);
	padding-inline: 0.5rem;
	box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
	.ant-menu-item {
		span.ant-menu-title-content {
			color: var(--text-normal);
			&:hover {
				color: var(--text-primary);
			}
		}
	}
}

.ant-menu-vertical {
	font-size: rem(16px);
	font-weight: 600;
	color: var(--text-normal);
	background: transparent !important;
	margin: 0 !important;
	li.ant-menu-item:not(.ant-menu-item-selected) {
		color: var(--text-normal);
	}
}

li.ant-menu-item-selected,
li.ant-menu-submenu-selected {
	color: var(--text-primary) !important;
	background-color: transparent !important;
}

li.ant-menu-item-active {
	color: var(--text-primary) !important;
	cursor: pointer;

	&:active {
		background: transparent !important;
	}

	&:focus-within {
		background: transparent !important;
	}
}

li.ant-menu-item-only-child {
	&:active {
		background: transparent !important;
	}

	&:focus-within {
		background: transparent !important;
	}
}

.profile-container {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	gap: rem(15px);
}

.ant-badge-count {
	font-size: rem(8px) !important;
	font-weight: 700;
	color: #fff !important;
	background-color: var(--add-red);
	box-shadow: none !important;
}

.header-search {
	position: absolute;
	max-width: 100%;
	width: var(--search-width-close);
	padding: 0.5rem 0;
	transition: all 0.3s;
	margin-right: rem(20px);
	height: 100%;
	background: var(--bg);
	@include max-xs {
		display: none;
	}
	& > div {
		background-color: var(--bg-light);
		border-radius: rem(50px);
		transition: all 0.3s;
	}
}

.headerClicked {
	// padding: 0;
	width: var(--search-width);
	& > div {
		border: 1px solid var(--text-normal);
	}
}

.header-bellIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 rem(12px) 0 0;
	gap: rem(12px);
	cursor: pointer;

	.logo-title {
		margin: 0 !important;
		line-height: rem(19px);
		font-weight: 600;
		font-size: rem(16px);
		color: var(--logo);
	}
	@include min-md {
		margin-right: rem(40px);
	}
}

.header-avatar {
	width: rem(32px);
	height: rem(32px);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	> img {
		border-radius: 50%;
		width: rem(32px);
		height: rem(32px);
		object-fit: cover;
		object-position: center;
	}
}

.customPopover-topPosition {
	top: $top-bar-height !important;
	max-width: 100vw;
	@include max-sm {
		top: rem(60px) !important;
		right: 0 !important;
		left: 0 !important;
	}
	.ant-popover-inner {
		padding: 1.5rem;
		box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
		border: 0.1rem solid var(--border-popup);
		font-weight: 600;
	}
}
