.container {
	border-radius: 8px;
	overflow: hidden;
	background-color: var(--bg);
}

.itemContainer {
	max-height: 160px;
	overflow-y: scroll;
}

.listItem {
	height: 40px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;

	.firstElement {
		width: auto;
		padding: 8px 16px;
	}

	.secondElement {
		width: 264px;
		padding: 8px 16px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}

.selectedListItem {
	background-color: #01b2d026;
	color: var(--text-primary);
}

.headerItem {
	background-color: var(--border-popup);
	cursor: default !important;
}
