@import 'functions';

.formInput-container {
	display: flex;
	flex-direction: column;
	color: var(--text-normal);
	width: 100%;
	gap: rem(5px);
	& > .ant-input-affix-wrapper.ant-input-password {
		height: rem(48px);
		padding: rem(7px) rem(15px);

		&:focus-within {
			outline: none;
			box-shadow: none;
		}
		& > .ant-input-lg {
			border-color: var(--text-normal);
			color: var(--text-normal) !important;
			line-height: rem(19px) !important;
			background-color: transparent;
			font-size: rem(16px);
			font-weight: 600;
			&::placeholder {
				font-weight: 600;
				font-size: rem(16px);
				color: var(--text-normal);
				opacity: 0.7;
			}
		}
	}
	& > .ant-input-lg {
		border: rem(1.5px) solid var(--border-popup);
		height: rem(48px);
		line-height: rem(19px) !important;
		border-radius: rem(50px);
		background-color: transparent;
		color: var(--text-normal) !important;
		padding-left: rem(15px);
		font-size: rem(16px);
		font-weight: 600;
		&::placeholder {
			font-weight: 600;
			font-size: rem(16px);
			color: var(--text-normal);
			opacity: 0.7;
		}
		&:hover {
			border: rem(1.5px) solid var(--text-normal);
			outline: none;
			box-shadow: none;
		}
		&:focus {
			outline: none;
		}
		&:focus-within {
			box-shadow: none;
		}
	}
	.input-password {
		border: rem(1.5px) solid var(--border-popup);
		height: rem(40px);
		line-height: rem(19px) !important;
		border-radius: rem(50px);
		background-color: transparent;
		color: var(--text-normal);
		padding-left: rem(15px);
		font-size: rem(16px);

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0px 1000px var(--bg) inset !important;
			box-shadow: 0 0 0px 1000px var(--bg) inset !important;
		}

		&:hover {
			border: rem(1.5px) solid var(--text-normal);
			outline: none;
			box-shadow: none;
		}
		&:focus {
			outline: rem(1.5px) solid var(--text-normal);
		}
		&:focus-within {
			box-shadow: none;
		}

		& > .ant-input-suffix {
			svg {
				color: var(--text-normal) !important;
				width: rem(22px) !important;
				height: rem(22px) !important;
			}
		}
	}
	& > .formInput-label {
		font-weight: 600;
		font-size: rem(14px);

		line-height: rem(17px);
		padding-bottom: rem(3px);
		color: var(--text-normal);
	}
	& > .errorMessage {
		width: 100%;
		height: rem(24px);
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		color: var(--add-red);
		font-size: rem(14px);
		font-weight: 600;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0px 1000px var(--bg) inset !important;
	box-shadow: 0 0 0px 1000px var(--bg) inset !important;
	-webkit-text-fill-color: var(--text-normal) !important;
}
