.mc-moreMenuWrapper .ant-popover-inner {
	box-shadow: 0 0.125rem 1.875rem var(--bg-60);
	border: 0.1rem solid var(--border-popup);
	padding: 0;
}

.mc-moreMenu {
	display: flex;
	padding: 0;
	margin: 0;
	list-style: none;
	flex-direction: column;
	border-radius: 1.5rem;
	min-width: 12.5rem;

	& > * {
		background: transparent;
		display: flex;
		align-items: center;
		width: 100%;
		// height: rem(45px);
		height: 2.8125rem;
		border: 0;
		color: var(--text-normal);
		font-weight: 600;
		font-size: 1rem;
		padding: 0.75rem 1rem;
		user-select: none;
		&:not(:disabled) {
			cursor: pointer;
		}
		&:disabled,
		&.link-disabled {
			pointer-events: none;
			cursor: not-allowed;
			color: var(--text-disabled);
			&:hover {
				opacity: 1;
			}
		}
		&:hover {
			opacity: 0.7;
			color: var(--text-subtitle);
		}
	}
}

.mc-moreMenuTrigger {
	&.mc-x-open .mc-iconButton {
		color: var(--text-subtitle);
	}
}
