@import 'functions';
@import 'breakpoints.module.scss';

.sidebar {
	border-right: 2px solid var(--bg);
	padding: rem(8px) 0 0 0;
	margin-left: calc((100vw - var(--max-width)) / 2);
	background: var(--surface) !important;
	@include max-xl {
		margin-left: 0;
		padding: rem(32px) 0 0 rem(32px);
	}
	.sidebar-toggle-button {
		padding: 0.5rem;
		margin-left: -0.5rem;
		cursor: pointer;
		transition: all 0.3s;
		width: fit-content;
		border-radius: 0.25rem;

		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}

		display: flex;
		justify-content: center;
		align-items: center;
	}
	.ant-menu-item-disabled {
		cursor: default !important;
		pointer-events: none;
	}
}
.without-sidebar {
	width: 0;
	background: var(--surface) !important;
	margin-left: calc((100vw - var(--max-width)) / 2);
	@include max-xl {
		margin-left: 0;
	}
}

.sidebarMenu {
	font-style: normal;
	font-weight: 600;
	font-size: rem(16px);
	line-height: rem(18px);
	color: var(--text-normal);
	background: var(--surface);

	.ant-layout {
		outline: none !important;
	}

	.ant-menu-item-group-title {
		font-size: rem(16px);
		color: var(--text-normal);
		padding-left: 0;
	}
	li.ant-menu-item-disabled .ant-menu-title-content {
		color: var(--text-disabled);
	}
	li.ant-menu-item:not(.ant-menu-item-selected) {
		span.ant-menu-title-content {
			color: var(--text-normal);
		}
		&:hover {
			span.ant-menu-title-content {
				color: var(--text-primary);
			}
		}
	}
}

.ant-menu-item {
	padding-left: 0 !important;
	&:not(.ant-menu-item-disabled):focus-visible {
		outline: none !important;
	}
}
.ant-menu-item-selected {
	background-color: transparent !important;
	color: var(--primary) !important;
}
.ant-menu-item-active {
	background-color: transparent !important;
	color: var(--primary) !important;
}
.ant-menu-root:focus-visible {
	outline: none !important;
}
.ant-menu-item-divider {
	border-color: var(--border-popup-inside) !important;
	border-top-width: 1px !important;
	border-width: 0;
	width: 86%;
	margin-block: rem(12px) !important;
}

.disabledIconStyle {
	color: var(--text-disabled);
	filter: 'grayscale(100%)';
	opacity: 0.5;
}

.disabledLabelStyle {
	color: var(--text-disabled);
	opacity: 0.5;
}
