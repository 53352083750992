@import '../assets/styles/breakpoints.module';

// The root element get the `.mc-desktop` class when running in the desktop app
:root.mc-desktop {
	// The combined outer chrome border (1px) and padding (2px)
	$desktop-chrome-border: 3px;
	$desktop-chrome-radius: 8px;
	$desktop-inner-radius: 4px;
	$desktop-titlebar-height: 40px;
	$desktop-titlebar-button-width: 46px;

	// This is used by desktop/hackyZoom to allow "zooming"
	--zoom: 1;
	font-size: calc(var(--zoom) * 16px);
	svg.zoom-resize,
	img.zoom-resize {
		zoom: calc(var(--zoom) * 100%);
	}

	body,
	& {
		background: transparent;
		border-radius: $desktop-chrome-radius;
	}

	#root {
		border: 1px var(--border-popup) solid;
		border-radius: $desktop-chrome-radius;
		padding: 2px;
		background-color: var(--bg);
		height: 100vh;
		overflow: hidden;

		.layout-wrapper {
			border-bottom: 1px var(--surface) solid;
			position: absolute;
			bottom: $desktop-chrome-border;
			top: $desktop-titlebar-height;
			left: $desktop-chrome-border;
			right: $desktop-chrome-border;
			height: auto;
			width: auto;
			background: var(--bg);
			overflow: hidden;

			.content-wrapper {
				overflow: auto;
				border-radius: $desktop-inner-radius;
			}

			.header-wrapper {
				border-top: 1px var(--border-popup) solid;
				.header-content {
					background: transparent;
					.logo-container {
						display: none;
					}
				}
			}

			.main-wrapper {
				border-top: 1px var(--surface) solid;
				border-radius: $desktop-inner-radius;
			}
		}

		.titlebar {
			border-radius: $desktop-chrome-radius $desktop-chrome-radius 0 0;
			height: $desktop-titlebar-height;
			user-select: none;
			display: flex;
			position: fixed;
			align-items: center;
			top: 0;
			left: 0;
			right: 0;
			overflow: hidden;
			z-index: 8888;
			font-size: 16px;

			.titlebar-button {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				width: $desktop-titlebar-button-width;
				height: $desktop-titlebar-height;
				color: var(--text-normal);
				&:hover {
					color: white;
					background: var(--bg-active);
					&#titlebar-close {
						background: #c42b1c;
					}
				}
			}

			.titlebar-icon {
				padding-left: 16px;
				user-select: none;
				pointer-events: none;
				height: 24px;
			}
			.titlebar-version {
				color: var(--primary);
				font-weight: 500;
				font-size: 7.5px;
				line-height: 9px;
				margin: 16px 0 16px 4px;
				flex: 1;
				user-select: none;
				pointer-events: none;
				display: flex;
				flex-direction: row;
				align-items: center;
			}
			.titlebar-channel {
				color: var(--primary);
				margin-left: 4px;
				text-transform: uppercase;
				line-height: 1em;
				font-size: 6px;
				border-radius: 3.5px;
				border: 1px currentColor solid;
				padding: 1px 2px;
				vertical-align: baseline;
			}
		}
	}

	// Zoom fixes ------------------------------------------------------------------------------
	div.ant-select-selector {
		height: 2rem;
	}

	.ant-badge-count {
		height: 1rem;
		min-width: 1rem;
		margin-top: 0.25rem;
		right: 0.5rem;
		border-radius: 50%;
		line-height: 1rem;
	}

	.customPopover-topPosition {
		top: calc($desktop-titlebar-height + 4rem) !important;
	}

	.mc-search {
		.mc-search-container {
			@include min-md {
				max-width: calc(100vw - 9rem);
			}
		}
	}
}
