@use '_mixins';
@import 'functions';

.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.signupForm {
	@extend .form;
	gap: rem(12px) !important;
	.formInput-container {
		margin-bottom: rem(16px);
		position: relative;
		& > .errorMessage {
			position: absolute;
			bottom: rem(-22px);
			right: 0;
		}
	}
}

.button {
	@include mixins.signButton;
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.errorField {
	width: 100%;
}
.errorField:not(:empty) {
	height: rem(52px);
}
.errorMessage {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--text-subtle);
	font-size: rem(16px);
	background-color: var(--add-red);
}

.line {
	@include mixins.divider;
	margin: 2rem 0;
}

.wrapper {
	width: 100%;
}
.textLink {
	display: flex;
	justify-content: flex-end;
	gap: rem(6px);
	font-size: rem(14px);
	color: var(--text-subtle);
}

.link {
	font-weight: 600;
	color: var(--text-primary);
	cursor: pointer;
}

.forgotPassText {
	margin-top: rem(20px);
	font-size: rem(14px);
	color: var(--text-subtle);
}
.forgotPassLink {
	text-decoration: underline 1px var(--text-subtle);
	cursor: pointer;
}
