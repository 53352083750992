@import 'functions';

.motionbutton {
	&.ant-btn {
		font-size: rem(18px);
		font-weight: 600;
		color: var(--button-text);
		background: var(--button-bg);
		box-shadow: none;
		padding: rem(8px) rem(32px) !important;
		padding-inline-start: rem(32px) !important;
		padding-inline-end: rem(32px) !important;

		&.ant-btn-lg {
			font-size: rem(18px);
			font-weight: 600;
			height: rem(38px);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:not(:disabled):hover {
			background-color: var(--bg-active);
		}

		&:disabled {
			cursor: default;
			opacity: 0.3;
		}
	}

	&.ant-btn-primary {
		background-color: var(--button-bg);
		font-size: rem(18px);
		font-weight: 600;

		&:disabled {
			color: var(--text-disabled);
			border-color: var(--text-disabled);
			opacity: 0.3;

			&:hover {
				background-color: rgba(0, 0, 0, 0.04) !important;
			}
		}

		&:not(:disabled):hover {
			background-color: var(--bg-active);
		}
	}

	&.ant-btn-ghost {
		font-size: rem(18px);
		font-weight: 600;
		background-color: transparent;
		color: var(--text-normal);
		border: rem(1px) solid var(--text-normal);

		&:disabled {
			color: var(--text-disabled);
			border-color: var(--text-disabled);
			opacity: 0.8;
			cursor: default;

			&:hover {
				background-color: rgba(0, 0, 0, 0.04) !important;
			}
		}

		&:not(:disabled):hover {
			background-color: var(--surface) !important;
			opacity: 0.8;
		}
	}

	&.ant-btn-default {
		font-size: rem(18px);
		font-weight: 600;
		background-color: var(--button-secondary);
		color: var(--button-text);
		border: rem(1px) solid var(--button-secondary);

		&:not(:disabled):hover {
			background-color: var(--button-secondary) !important;
			color: var(--button-text) !important;
			border: rem(1px) solid var(--button-secondary) !important;
		}
	}
}
