@use '_mixins';
@import 'functions';
@import 'breakpoints.module.scss';

// Global styles
:global(.mc-desktop) {
	.container {
		border-radius: 6px;
		top: 3px;
		bottom: 3px;
		left: 3px;
		right: 3px;
	}

	.leftHalfContainer {
		@include max-sm {
			border-radius: 6px;
			top: 0px;
			bottom: 0px;
		}
	}
}

// Layout
.container {
	display: flex;
	justify-content: center;
	gap: 2rem;
	@include max-sm {
		flex-direction: column-reverse;
		min-height: inherit;
	}
}

.navBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	position: sticky;
	top: 0;
	z-index: 999;
	background-color: var(--contrast-color);
	padding: 2rem;
	.exploreFeatures {
		display: flex;
		align-items: center;
		gap: 2rem;
		.lightModeSwitch {
			@include max-sm {
				position: absolute;
				top: 1rem;
				right: 1rem;
			}
			@include max-xxs {
				position: initial;
			}
		}
		.HeaderButtonWrapper {
			@include max-sm {
				display: none;
			}
		}
	}
	@include max-sm {
		flex-direction: column;
		gap: 1rem;
		padding: 1rem;
	}
	.logo {
		@include max-sm {
			width: 100%;
			display: flex;
			align-items: center;
		}
		@include max-xxs {
			justify-content: center;
		}
	}
}

.containerWrapper {
	background-color: var(--contrast-color);
}

.leftHalfContainer {
	display: flex;
	justify-content: center;
	flex: 6;
}

.rightHalfContainer {
	background-color: var(--contrast-color);
	display: flex;
	flex: 4;
	justify-content: flex-start;
	align-items: center;
	padding: 0 2rem 0 0;
	@include max-sm {
		justify-content: center;
		padding-inline: 1rem;
	}
}

// Form styles
.signFormWrapper {
	border-radius: 1.5rem;
	height: unset;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include max-sm {
		height: auto;
		z-index: 9;
		padding: rem(16px);
		backdrop-filter: blur(12px);
		background: color-mix(in oklch, var(--bg), transparent 20%);
	}
}

.forgotPassFormWrapper {
	@extend .signFormWrapper;
	height: rem(511px);
	gap: rem(32px);
}

.setPassFormWrapper {
	@extend .signFormWrapper;
	height: rem(513px);
	justify-content: space-between;
}

// Typography
.signFormContentText {
	margin: 0;
	font-size: rem(40px);
	font-weight: 900;
	color: var(--text-subtle);
	letter-spacing: -0.4px;
	text-align: left;
	text-wrap: balance;
}

.subsignFormContentText {
	font-size: rem(22px);
	color: var(--text-normal);
	margin-bottom: rem(32px);
	font-weight: 400;
	text-align: left;
}

// Links and buttons
.link {
	color: var(--text-primary);
	font-size: rem(20px);
	font-weight: 700;
	cursor: pointer;
}

.loginLink {
	@extend .link;
}

.button {
	@include mixins.signButton;
}

.ctaButton {
	background-color: var(--text-primary);
	color: var(--bg);
	border: none;
	padding: rem(12px) rem(24px);
	border-radius: rem(4px);
	font-size: rem(16px);
	cursor: pointer;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: var(--contrast-color);
	}
}

// Guest login
.guest {
	font-weight: 600;
	color: var(--text-primary);
	font-size: rem(16px);
	cursor: pointer;
	border-bottom: 1px solid var(--text-primary);
}

.guestText {
	margin: 0;
	font-size: rem(16px);
	font-weight: 600;
	color: var(--text-normal);
	margin-bottom: rem(32px);
}

// Info and signup
.infoSignup {
	margin-bottom: rem(32px);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: rem(12px);
	font-size: rem(16px);
	color: var(--text-normal);
	@include max-sm {
		margin-top: rem(16px);
	}
}

// signFormContent
.signFormContent {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.25rem;
}

// Forms
.forgotPassForm,
.setPassForm {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.forgotPassTitle {
	color: var(--text-normal);
	font-size: rem(16px);
}

.wrapper {
	width: 100%;
}

.formColumn {
	display: flex;
	gap: rem(16px);
}

// Messages
.forgotPassWrapper,
.setPassMessageWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 auto;
	width: 100%;
}

.forgotPassText,
.forgotPassMessage,
.setPassMessage {
	text-align: center;
	color: var(--text-subtle);
	font-weight: 600;
	font-size: rem(20px);
}

.forgotPassText {
	padding: rem(32px) 0 rem(16px);
}

.setPassMessage {
	padding-top: rem(48px);
}

.forgotPassMessageWrapper {
	@extend .forgotPassWrapper;
	justify-content: space-between;
	padding-top: rem(128px);
}

.setPassNotFoundWrapper {
	height: 100%;
	display: flex;
	align-items: center;
}

// Utility classes
.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.line {
	@include mixins.divider;
	margin: 0;
	margin-bottom: rem(16px);
}

.setPassPageDivider {
	@include mixins.divider;
	margin: rem(34px) 0 rem(64px);
}

.forgotPassPageDivider {
	@include mixins.divider;
	margin: rem(11px) 0 rem(32px) 0;
}

.noElementsInfo {
	display: flex;
	align-items: center;
	gap: rem(10px);
	color: var(--text-normal);
	font-size: rem(28px);
	font-weight: normal;
}

// Error styles
.error {
	@include mixins.error;
	height: rem(24px);
	margin: 0;
}

.errorField {
	width: 100%;
	height: rem(52px);
	margin: rem(30px) 0;
}

.errorMessage {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--text-subtle);
	font-size: rem(16px);
	background-color: var(--add-red);
}

// Device image
.deviceImageContainer {
	display: flex;
	align-items: center;
	max-height: 80vh;
	justify-content: flex-end;
	background-color: var(--contrast-color);
	padding-bottom: rem(64px);
}

.deviceImage {
	max-width: 100%;
	max-height: 100%;
	@include max-sm {
		max-width: 100%;
	}
	mask-image: linear-gradient(
		to left,
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 0.7) 50%,
		rgba(0, 0, 0, 0) 100%
	);
}

// Discover section
.discoverSection {
	padding: rem(64px) rem(32px);
	background-color: var(--bg);
	@include max-sm {
		padding: 2rem 1rem;
	}
}

.discoverContent {
	max-width: 1216px;
	margin: 0 auto;

	.sectionTitle {
		font-size: rem(48px);
		color: var(--text-subtle);
		margin-bottom: rem(8px);
		font-weight: 900;
	}

	.discoverSubtitle {
		font-size: rem(18px);
		color: var(--text-normal);
		margin-bottom: rem(32px);
		font-weight: 500;
	}
}

.featureCards {
	display: flex;
	justify-content: space-between;
	gap: rem(24px);
	flex-wrap: wrap;

	@include max-sm {
		flex-direction: column;
	}
}

.featureCard {
	background-color: var(--contrast-color);
	border: 1px solid var(--border-popup);
	border-radius: rem(16px);
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.featureCardContent {
		padding: rem(32px);

		.descriptionWrapper {
			position: relative;
			height: 3em; // Adjust this to fit two lines of text
			overflow: hidden;
			margin-bottom: 1rem;
		}

		.description {
			margin: 0;
			transition: max-height 0.3s ease;

			&.expanded {
				max-height: none;
			}
		}

		.fadeOverlay {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1.5em; // Adjust as needed
			background: linear-gradient(
				to bottom,
				transparent,
				var(--contrast-color)
			);
		}

		.expandButton {
			background: none;
			border: none;
			color: var(--text-primary);
			cursor: pointer;
			padding: 0;
			margin-bottom: 1rem;
			font-size: 0.9em;
			text-decoration: underline;
		}

		.collapsePanel {
			margin-bottom: 1rem;

			:global(.ant-collapse-header) {
				padding: 0;
				color: var(--text-primary);
				font-size: 0.9em;
				font-weight: 500;
				background: var(--contrast-color);
			}

			:global(.ant-collapse-content-box) {
				padding: 0.5rem 0 0;
			}
		}

		p {
			font-size: rem(16px);
			color: var(--text-subtle);
			margin-bottom: rem(16px);
			font-weight: 400;
		}
	}

	h3 {
		font-size: rem(28px);
		color: var(--text-subtle);
		margin-bottom: rem(4px);
	}

	h4 {
		font-size: rem(14px);
		color: var(--text-subtle);
		font-weight: 400;
		margin-bottom: rem(16px);
	}

	p {
		font-size: rem(18px);
		color: var(--text-subtle);
		margin-bottom: rem(24px);
		font-weight: 400;
	}

	.imagePlaceholder {
		min-height: rem(200px);
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}

	.featureImage {
		object-fit: cover;
		transition: transform 0.3s ease;
		transform: scale(1.01);
		width: 100%;

		&:hover {
			transform: scale(1.05);
		}
	}
}

.gettingStartedSection {
	background-color: var(--contrast-color);
	padding: rem(64px) rem(32px);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@include max-sm {
		flex-direction: column;
		padding: rem(32px) rem(16px);
	}
}

.gettingStartedContent {
	flex: 1;
	max-width: rem(1216px);
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;

	@include max-sm {
		flex-direction: column;
	}
}

.gettingStartedContentWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.gettingStartedText {
	flex: 1;
	text-align: left;
	margin-right: rem(32px);
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-basis: 100%;

	@include max-sm {
		margin-right: 0;
		margin-bottom: rem(32px);
		text-align: center;
	}

	.sectionTitle {
		font-size: rem(40px);
		color: var(--text-subtle);
		margin-bottom: rem(16px);
		font-weight: 900;
	}

	h3 {
		font-size: rem(24px);
		color: var(--text-normal);
		font-weight: 400;
	}
}

.noAccountImageWrapper {
	margin-bottom: rem(24px);
	width: 100%;
	text-align: center;
}

.noAccountImage {
	width: rem(120px);
	height: rem(120px);
	border-radius: 50%;
	object-fit: cover;
	border: 4px solid var(--text-primary);
}

.stepCards {
	flex: 2;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: rem(24px);

	@include max-sm {
		grid-template-columns: 1fr;
	}
}

.stepCard {
	background-color: var(--bg);
	border-radius: rem(8px);
	padding: rem(20px);
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	h3 {
		font-size: rem(18px);
		color: var(--text-subtle);
		margin: rem(10px) 0;
	}

	p {
		font-size: rem(14px);
		color: var(--text-normal);
	}
}

.stepIcon {
	width: rem(40px);
	height: rem(40px);
	background-color: var(--text-primary);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: rem(10px);

	span {
		font-size: rem(18px);
		font-weight: bold;
		color: var(--bg);
	}
}

.signUpPrompt {
	text-align: center;
	margin-block: rem(32px);

	p {
		font-size: rem(16px);
		color: var(--text-normal);
		margin-bottom: rem(12px);
	}
}

.signUpButton {
	background-color: var(--text-primary);
	color: var(--bg);
	border: none;
	padding: rem(10px) rem(20px);
	font-size: rem(16px);
	border-radius: rem(4px);
	cursor: pointer;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: var(--text-primary);
	}
}

.ghostButton {
	display: inline-block;
	padding: rem(8px) rem(16px);
	border: 0 solid var(--text-primary);
	border-radius: 1rem;
	color: var(--text-subtle);
	font-size: rem(14px);
	font-weight: 400;
	text-decoration: none;
	text-align: center;
	background-color: transparent;
	transition: background-color 0.3s ease, color 0.3s ease;
	cursor: pointer;

	&:hover {
		background-color: var(--text-primary);
		color: var(--bg);
	}
}
