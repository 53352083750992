@import 'breakpoints.module.scss';

.stepsTour {
	.stepsTourLeft {
		background-color: var(--bg-dark);
		border-radius: 0.75rem;
		display: flex;
		padding: 1.5rem;
		margin-left: 1rem;
		flex-direction: column;
		align-items: flex-start;
		align-self: stretch;
		min-width: 20%;
		@include max-xs {
			min-width: 100%;
			margin-left: 0;
			padding: 1rem;
		}
		.stepsTourTitle {
			margin-bottom: 1.5rem;
			color: var(--text-subtitle);
			white-space: nowrap;
			@include max-xs {
				margin-bottom: 0;
				white-space: wrap;
			}
		}
	}
	.stepsTourRight {
		flex: 1;
		padding: 0 1.5rem;
		@include max-sm {
			padding: 0;
		}
	}
	@include max-sm {
		flex-direction: column;
	}
}
.stepsTourFooter {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	border-top: 1px solid var(--border-popup);
	padding: 1rem 0 0;
	margin: 1rem 0 0;
	gap: 1rem;
	@include max-xs {
		justify-content: center;
	}
}
