.pageContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.pageContent {
	flex: 1;
}

.buttonBar {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.pageTitle {
	color: var(--text-subtle);
	font-size: 20px;
	font-weight: 600;
	word-wrap: break-word;
}

.pageSubTitle {
	color: var(--text-normal);
	font-size: 14px;
	font-weight: 600;
	word-wrap: break-word;
}

.pageText {
	color: var(--text-normal);
	font-size: 13px;
	font-weight: 500;
	word-wrap: break-word;
}

.divider {
	width: 100%;
	border-bottom: 1px var(--border-popup-inside) solid;
}

.supportIcon {
	height: 24px;
	width: 24px;
	& svg:hover path {
		stroke: #01b2d0 !important;
	}
}
