@import 'functions';

.window {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
}

.messageContainer {
	width: rem(304px);
	min-height: rem(195px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem;
	border: rem(1.5px) solid var(--border-popup);
	box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
	border-radius: 1.5rem;
	background-color: var(--bg);
}

.message {
	white-space: pre-wrap;
	font-size: rem(16px);
	font-weight: 600;
	line-height: rem(17px);
	color: var(--text-normal);
	word-break: normal;
	line-height: rem(28px);
}

.btnContainer {
	display: flex;
	justify-content: center;
	margin-top: rem(24px);
	gap: rem(24px);
}

.buttonOk {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: rem(8px) rem(32px);
	height: rem(38px);
	font-size: rem(18px);
	font-weight: 600;
	border: rem(1px) solid var(--button-bg);
	border-radius: rem(50px);
	cursor: pointer;
	color: var(--button-text);
	background: var(--button-bg);
	&:hover {
		background-color: var(--bg-active);
	}
}

.popUpContainer {
	@extend .messageContainer;
	width: rem(1124px);
	padding: 0;
	align-items: flex-start;
}

.title {
	padding: 1.5rem 0 0 1.5rem;
	color: var(--text-primary);
	font-size: rem(20px);
}
