@import 'functions';
@import 'breakpoints.module.scss';

.layout-wrapper {
	width: 100vw;
	background: var(--surface);
	margin: 0 auto;
	height: 100vh;
	overflow: auto;
	display: flex;
	flex-direction: column;
}

.header-wrapper {
	background: var(--bg);
	margin: 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.content-wrapper {
	background: var(--surface);
	flex: 1;

	.top-banner {
		.banner-content {
			display: flex;
			align-items: center;
			max-width: var(--max-width);

			padding: 0 0.5rem;

			margin-left: auto;
			margin-right: auto;

			h4 {
				color: var(--text-normal);
				font-weight: 600;
				font-size: 1rem;
				margin-left: auto;
				margin-right: auto;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				min-width: none;
				a {
					margin-left: 0.5ch;
				}
			}
		}
		background: var(--bg-dark);

		// no need in mobile
		@include max-sm {
			display: none;
		}
		width: 100%;
	}
}

.main-wrapper {
	background: var(--surface);
	width: 100%;
	margin: 0 auto;
	padding-bottom: rem(64px);

	@include max-xl {
		max-width: 100%;
	}
}

.flex,
.flex-col,
.flex-row {
	display: flex;
}

.flex-1 {
	flex: 1;
}

.flex-col {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.between {
	justify-content: space-between;
}

.center {
	justify-content: center;
	align-items: center;
}

.space-around {
	justify-content: space-around;
}

.space-evenly {
	justify-content: space-evenly;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.wrap {
	flex-wrap: wrap;
}

.no-wrap {
	flex-wrap: nowrap;
}

.w-full {
	width: 100%;
}

.gap-1 {
	gap: 1rem;
}

.gap-0-5 {
	gap: 0.5rem;
}

/* Responsive Flexbox Utilities (for different screen sizes) */
@media (max-width: 768px) {
	.flex-mobile {
		display: flex;
	}

	.flex-col-mobile {
		display: flex;
		flex-direction: column;
	}

	.center-mobile {
		justify-content: center;
		align-items: center;
	}
}

.layout-mainContent {
	padding: rem(32px) 0 rem(32px) rem(32px);
	padding-right: calc((100vw - var(--max-width)) / 2 + rem(22px));

	@include max-xl {
		padding-right: rem(22px);
	}
}

::-webkit-scrollbar {
	width: rem(10px);
	margin-left: rem(10px);
}

::-webkit-scrollbar-thumb {
	background: var(--bg-light);
	margin-left: rem(10px);
	border-radius: rem(20px);

	&:hover {
		background: var(--button-secondary);
	}
}

::-webkit-scrollbar-corner {
	background: var(--surface);
}

@include max-sm {
	.sm-screen-hidden {
		display: none !important;
	}
}
