@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';

.fieldWrapper {
	width: 100%;
	border: rem(1.5px) solid var(--border-popup);
	border-radius: 1.5rem;
}

.applicationWrapper {
	display: flex;
	justify-content: space-between;
	padding: rem(12px) rem(16px);
	min-height: rem(72px);
	width: 100%;
	border-bottom: rem(1.5px) solid var(--border-popup-inside);
}

.itemContainer {
	align-items: center;
	display: inline-flex;
}

.iconLabel {
	margin-left: rem(12px);
	align-items: center;
	display: inline-flex;
	color: var(--text-normal);
}

.applicationSelect {
	padding: 0;
	min-height: rem(72px);
	width: 100%;
	border: none;
}

.applicationSelect > * {
	padding: rem(12px) rem(16px) !important;
}
