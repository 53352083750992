@import 'functions';

.fileCard-container {
	display: flex;
	flex-direction: column;
	width: rem(240px);
	gap: rem(16px);

	.fileCardImage-container {
		height: rem(128px);
		background-color: var(--bg);
		border-radius: rem(24px);
		.fileCardImage {
			width: 100%;
			height: 100%;
		}
		.fileCardImage > img {
			border-radius: rem(24px);
			width: 100%;
			max-width: 100%;
			max-height: 100%;
		}
		.fileCardNoImage {
			position: relative;
			&:before {
				background: var(--text-subtle);
				content: '';
				height: rem(2px);
				left: rem(8px);
				position: absolute;
				top: rem(64px);
				width: rem(224px);
				transform: rotate(-25deg);
			}
			&:after {
				background: var(--text-subtle);
				content: '';
				height: rem(2px);
				left: rem(8px);
				position: absolute;
				top: rem(64px);
				width: rem(224px);
				transform: rotate(25deg);
			}

			.fileCardNoImageLabel {
				position: absolute;
				font-size: rem(18px);
				line-height: rem(16px);
				right: rem(24px);
				top: rem(8px);
				color: var(--text-normal);
			}
			.fileCardNoImageSpan {
				position: absolute;
				font-size: rem(18px);
				line-height: rem(16px);
				top: rem(56px);
				left: rem(96px);
				color: var(--text-normal);
			}
		}
	}
	.fileCardTitle-container {
		display: flex;
		flex-direction: column;
		gap: rem(8px);
		.fileCardTitle {
			color: var(--text-subtle);
			font-size: rem(14px);
			line-height: rem(16px);
			margin: 0;
		}
		.fileCardAdditionTime {
			color: var(--text-normal);
			font-size: rem(12px);
			line-height: rem(16px);
			margin: 0;
		}
	}
}
