@use 'mixins';
@import 'functions';

.container {
	margin-top: rem(6px);
	margin-bottom: rem(6px);

	&:first-child .collapsibleHeader {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	&:last-child .collapsibleHeader {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	&:last-child .expandedHeader {
		border-radius: 0;
	}

	&:last-child .itemsContainer {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}

.searchContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: rem(24px);
	margin-top: rem(16px);
	margin-bottom: rem(32px);
}

.searchFieldWrapper {
	flex: auto;
	background: var(--bg);
	border-radius: 1.875rem;
	padding: 0.6875rem 0;
}

.collapsibleHeader {
	display: flex;
	padding: rem(16px);
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	cursor: pointer;
	overflow: hidden;
	margin-top: rem(4px);
	border-radius: 0;
}

.collapsedHeader {
	background: var(--bg);
	color: var(--text-normal);
	border-radius: 0;
	transition: background 0.4s ease, color 0.4s ease, border-radius 1.6s ease;

	.rightHeader {
		color: var(--text-normal);
	}
}

.expandedHeader {
	background: var(--bg-active);
	color: var(--button-text);
	border-radius: 0;
	transition: background 0.3s ease, color 0.3s ease;

	.dateHeader {
		color: var(--button-text);
	}
}

.itemsContainer {
	background: var(--bg);
	border-radius: 0;
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.4s ease;

	&.expanded {
		max-height: rem(600px);
		overflow-y: scroll;
	}
}

.innerContent {
	padding: rem(24px);
	color: var(--text-normal);
}

.rightHeader {
	display: flex;
}

.dateHeader {
	float: right;
	margin-left: rem(10px);
	margin-right: rem(10px);
}
