.mc-search {
	.mc-search-container {
		max-width: var(--max-width);
		@include min-md {
			max-width: calc(100vw - 23rem);
		}
		min-width: 300px;
		width: var(--search-width);
		position: relative;
		overflow: auto;
		max-height: 70vh;
		margin: -1.5rem;
		border-radius: 1.5rem;

		.mc-searchResult {
			display: flex;
			align-items: center;
			padding: 0.75rem 1rem;
			gap: 0.5rem;
			cursor: pointer;
			font-size: 1rem;
			font-weight: 600;

			color: var(--text-normal);
			.mc-x-category {
				opacity: 0.5;
			}

			&:hover {
				background-color: var(--bg-hover, var(--primary-dark));
				color: var(--button-text);
				path {
					fill: var(--button-text);
				}
			}
		}
	}
}
