@import 'functions';
@import 'breakpoints.module.scss';

// SelectEngineForHome

.selectEngineWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: rem(8px);
}

.labelIconName {
	display: flex;
	align-items: center;
	gap: rem(10px);
	width: 30%;
	@media screen and (max-width: 1680px) {
		width: 50%;
	}
}

.tag {
	border: 1.5px solid;
	border-radius: 8px;
	font-size: 12px;
	padding: 0 9px;
	line-height: normal;
}

.upgradeTagColor {
	border-color: var(--add-yellow) !important;
	color: var(--add-yellow) !important;
}

.latestReleaseTagColor {
	border-color: #ffffff !important;
	color: #ffffff !important;
}

:global(.lightMode) .upgradeTagColor,
:global(.lightMode) .latestReleaseTagColor {
	border-color: var(--primary) !important;
	color: var(--primary) !important;
}

.selectEngineField {
	width: 100%;
	height: rem(46px);
	display: flex;
	align-items: center;
	border-radius: 20px;
	font-size: rem(16px);
	border: 1px solid var(--bg-dark);
	background-color: var(--bg-dark);
	:global {
		.ant-select-selector {
			display: flex;
			align-items: center;
			font-size: rem(16px);
			background-color: var(--bg-dark) !important;
			border: none !important;
			box-shadow: none !important;
			& > .ant-select-selection-item {
				display: flex;
				align-items: center;
				height: rem(46px);
				color: var(--text-primary);
				font-weight: 600;
			}
		}
		.ant-empty-description {
			color: var(--text-normal);
		}
		.ant-select-dropdown {
			left: 0 !important;
			outline: rem(15px) solid transparent;
			background-color: var(--bg);
			border: 1px solid var(--border-popup);
			padding: 0;
			margin-top: rem(200px);
			border-radius: rem(16px);
			box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
		}

		.rc-virtual-list-holder {
			overflow-y: auto;
		}

		.ant-select-selection-placeholder {
			width: rem(200px);
			color: var(--text-normal);
		}

		.ant-select-arrow {
			color: var(--text-normal);
			font-weight: 900;
			font-size: rem(16px);
		}

		.ant-select-item-option-content {
			color: var(--text-normal);
			font-size: rem(16px);
			font-weight: 600;
			display: flex;
			align-items: center;
			padding-right: 20px;

			@include max-xs {
				flex-direction: column;
				align-items: flex-start;
				position: relative;
			}
		}

		div.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
			background-color: transparent;
			border-radius: 0;

			.ant-select-item-option-content {
				color: var(--text-primary);
				display: flex;

				&::after {
					flex: auto;
					display: flex;
					margin-right: -20px;
					justify-content: flex-end;
					content: url('../icons/checkIcon.svg');
					@include max-xs {
						position: absolute;
						right: 0;
						margin-right: 4px;
					}
				}
			}
		}

		div.ant-select-item.ant-select-item-option:hover {
			background-color: var(--bg-hover);
			> .ant-select-item-option-content {
				color: var(--primary);
			}
		}
	}

	&:focus-within {
		outline: none;
	}
}

// SelectEngineForTemplates

.wrapper {
	width: 100%;
	position: relative;
	cursor: pointer;
}

.filterField {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: rem(16px);
	padding: 0 rem(16px);
	border-radius: rem(50px);
	font-size: rem(16px);
	border: none;
	color: var(--text-normal);
	background: transparent;
}

.filterFieldDropMenu {
	position: absolute;
	width: 100%;
	margin-top: rem(7px);
	height: rem(400px);
	border: rem(1.5) solid var(--border-popup);
	background-color: var(--bg);
	box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
	border-radius: rem(16px);
	overflow-y: scroll;
	z-index: 10;
}

.filterFieldDropItem {
	padding: rem(12px) 0 rem(12px) rem(16px);
	font-size: rem(16px);
	color: var(--text-normal);
	&:hover {
		background-color: var(--bg-hover);
		cursor: pointer;
	}
}

.selected {
	padding: rem(12px) rem(12px) rem(12px) rem(16px);
	font-size: rem(16px);
	color: var(--text-primary);
	display: flex;
	justify-content: space-between;
	&:hover {
		background-color: var(--bg-hover);
		cursor: pointer;
	}
	&::after {
		content: url('../icons/checkIcon.svg');
	}
}
