@import 'functions';
@import 'breakpoints.module.scss';

.wrapper {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: rem(8px);
	cursor: pointer;
}

.filterField {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: rem(12px);
	border-radius: rem(50px);
	font-size: rem(18px);
	border: none;
	color: var(--text-normal);
	background: var(--bg-dark);
	:global {
		.ant-picker {
			padding: 0;
		}

		.ant-picker-clear {
			color: var(--text-normal) !important;
			background: var(--bg);
			svg {
				width: rem(19px) !important;
				height: rem(19px) !important;
			}
		}
	}
	@include max-sm {
		padding: 0 !important;
	}
}

.inputDateField {
	&:focus-within {
		outline: rem(2px) solid var(--text-normal);
	}
	:global {
		.ant-picker-panel-container {
			background-color: var(--bg);
			border: rem(1px) solid var(--border-popup);
			box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
			border-radius: rem(24px);
		}
		.ant-picker-cell-inner {
			color: var(--text-disabled);
			font-weight: 600;
		}
		.ant-picker-cell-in-view > .ant-picker-cell-inner {
			color: var(--text-normal);
			font-weight: 600;
		}
		.ant-picker-cell-selected > .ant-picker-cell-inner {
			color: var(--text-primary) !important;
			background-color: transparent !important;
			font-weight: 600;
		}
		.ant-picker-cell-inner::before {
			border: none !important;
		}
		.ant-picker-cell-today > .ant-picker-cell-inner {
			border: rem(1px) solid var(--text-primary) !important;
		}
		.ant-picker-today-btn {
			color: var(--text-primary);
		}
		.ant-picker-header button {
			color: var(--text-normal);
			&:hover {
				color: var(--primary);
			}
		}
		.ant-picker-content th {
			color: var(--text-normal);
		}
	}
}
.filterYear {
	:global {
		.ant-picker-input > input {
			color: var(--text-primary);
			font-size: rem(18px);
			font-weight: 600;
			&::placeholder {
				color: var(--text-primary);
			}
		}
		.ant-picker-suffix {
			color: var(--text-normal);
		}
	}
}

.filterYearPopup {
	@extend .inputDateField;
	:global {
		.ant-picker-panel-container {
			position: relative;
			left: rem(18px);
			top: rem(16px);
		}
		.ant-picker-year-panel {
			width: rem(242px);
		}
		.ant-picker-decade-btn {
			font-size: rem(16px);
		}
	}
}

.filterFieldDropMenu {
	position: absolute;
	width: 100%;
	border: rem(1.5) solid var(--border-popup);
	background-color: var(--bg);
	box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
	border-radius: rem(16px);
	overflow: hidden;
	z-index: 10;
}

.filterFieldDropItem {
	padding: rem(12px) 0 rem(12px) rem(16px);
	font-size: rem(18px);
	font-weight: 600;
	color: var(--text-normal);
	&:hover {
		background-color: var(--bg-hover);
		cursor: pointer;
		color: var(--primary);
	}
}

.selected {
	padding: rem(12px) rem(12px) rem(12px) rem(16px);
	font-size: rem(18px);
	font-weight: 600;
	color: var(--text-primary);
	display: flex;
	justify-content: space-between;
	&:hover {
		background-color: var(--bg-hover);
		color: var(--button-text);
		cursor: pointer;
		color: var(--primary);
	}
	&::after {
		content: url('../icons/checkIcon.svg');
	}
}
