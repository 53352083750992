@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';

.editFormWrapper {
	@include mixins.formWrapper;
	z-index: 99;
}

.coverImgContainer {
	position: relative;
	.infoTextWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: rem(8px);
		position: absolute;
		bottom: rem(24px);
		right: rem(24px);
	}
}

.uploadImgContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	gap: rem(24px);
	margin-bottom: rem(24px);
}

.addFormWrapper {
	@include mixins.formWrapper;
	border-radius: rem(24px);
	border: 1px solid var(--border-popup-inside);
	margin-bottom: rem(32px);
}

.frontImage {
	position: relative;
	width: 100%;
	aspect-ratio: 3/1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: rem(50px);
	background: var(--bg-60);
	color: var(--text-normal);

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: rem(50px);
	}
}

.avatarImage {
	width: rem(100px);
	height: rem(100px);
	object-fit: cover;
	object-position: center;
}

.editFrontImage {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: rem(22px);
	box-sizing: content-box;
	background: rgba(0, 0, 0, 0.2);
	border-radius: rem(50px);
	z-index: 0;
}

.avatar {
	position: relative;
	width: fit-content;
	display: flex;
	align-items: center;
	margin: 0 0 rem(24px);
}

.avatarApp {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: rem(100px);
	height: rem(100px);
	color: var(--text-normal);
	border-radius: rem(16px);
}

.editAvatar {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: rem(10px);
	box-sizing: content-box;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 50%;
	z-index: 15;
}

.editAvatarApp {
	@extend .editAvatar;
	border-radius: rem(16px);
}

input[type='file'] {
	display: none;
}

.inputWrapper {
	color: var(--text-normal);
	font-size: rem(14px);
}

.uploadContainer {
	display: flex;
	align-items: center;
	width: 100%;
	gap: rem(20px);
	padding-top: rem(24px);
	padding-bottom: rem(24px);
	margin-bottom: rem(24px);
	border-bottom: 1px solid var(--border-popup-inside);
}

.uploadText {
	display: inline-flex;
	align-items: center;
	gap: rem(10px);
	font-size: rem(16px);
	font-weight: 600;
	color: var(--text-primary);
}

.editForm {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: rem(30px);

	@include max-md {
		flex-direction: column;

		.column {
			width: 100%;
		}
	}
}

.column {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 0;
	width: calc(50% - rem(15px));
}

.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: rem(30px);
	width: 100%;
	flex-wrap: wrap;
}

.errorMessage {
	width: 100%;
	height: rem(24px);
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	color: var(--add-red);
	font-size: rem(14px);
	font-weight: 600;
}
.addToFavoriteWrapper {
	display: flex;
	color: var(--text-normal);
	gap: rem(12px);
	.addToFavoriteIcon {
		cursor: pointer;
		&.active {
			opacity: 0.8;
			color: var(--text-primary);
			&:hover {
				opacity: 1;
				color: var(--text-primary);
			}
			svg {
				fill: currentColor;
			}
		}
		&:hover {
			opacity: 1;
			color: var(--text-subtle);
		}
	}
}
.buttonsWrapper {
	display: flex;
	justify-content: flex-end;
	gap: rem(30px);

	@include max-md {
		margin-top: rem(24px);
		justify-content: center;
	}

	:global .mc-button {
		margin-left: 0;
		margin-right: 0;
	}
}

.statusButtonsWrapper {
	display: flex;
	justify-content: flex-start;
	gap: rem(16px);
}

.editNewsWindow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 20;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editNewsFormContainer {
	@include mixins.formWrapper;
	border-radius: rem(24px);
	max-width: 1400px;
	position: relative;
	left: rem(260px);

	@include min-xl {
		left: calc((100% - #{$xlBreakpoint}) / 2 + rem(270px)) !important;
	}

	@media screen and (max-width: 1700px) and (min-width: 1401px) {
		left: auto;
		right: 10px;
	}

	@include max-xl {
		left: 10px;
		right: 10px;
		width: calc(100% - 20px);
	}

	border: rem(1.5px) solid var(--border-popup);
	box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
}

.extraNewsField {
	display: flex;
	align-items: center;
	gap: rem(14px);

	.extraNewsTitle {
		color: var(--text-normal);
		font-size: rem(16px);
		font-weight: 600;
	}

	.extraNewsIcon {
		color: var(--add-orange);
		cursor: pointer;

		& svg {
			fill: var(--add-orange);
		}
	}

	.notExtraNewsIcon {
		color: var(--text-normal);
		cursor: pointer;

		& svg {
			fill: none;
		}
	}
}

.divider {
	border-bottom: 1.5px solid var(--border-popup);
	width: calc(100% + rem(48px));
	position: relative;
	left: rem(-24px);
}

.blockWrapper {
	padding: rem(24px) 0;
}

.blockHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: rem(24px);
}

.blockTitle {
	font-size: rem(20px);
	color: var(--text-subtle);
}

.blockMainPart {
	padding: rem(24px) 0 rem(12px);
}

.manageRequestsTitles {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: rem(52px);
	background-color: var(--bg);
	padding: rem(16px) 0;
	font-size: rem(16px);
	color: var(--text-subtle);
}

.dividerElements:not(:last-of-type) {
	border-bottom: 1.5px solid var(--border-popup);
	width: 100%;
}

.requestFileContainer {
	display: flex;
	gap: rem(24px);
}

.fileTextField {
	width: 100%;
}

.fileUploadIcon {
	display: flex;
	align-items: center;
	color: var(--text-normal);
	cursor: pointer;
}

.linkIcon {
	display: flex;
	align-items: center;
	gap: rem(16px);
	cursor: pointer;
	color: var(--text-normal);
	&:hover {
		color: var(--text-primary);
	}
}

.disabledLinkIcon {
	display: flex;
	align-items: center;
	gap: rem(16px);
	color: var(--border-popup);
}

.userDisplayBox {
	color: var(--text-normal);
	width: 100%;
	max-height: rem(260px);
	overflow-y: scroll;
	margin-top: rem(24px);
	padding-right: 1rem;
}

.userDisplayItem {
	padding-top: rem(16px);
	padding-bottom: rem(16px);
}

.userDisplayItem:not(:last-of-type) {
	border-bottom: 1px solid var(--border-popup);
}
