@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';

.container {
	display: flex;
	flex-direction: column;
	gap: rem(24px);
	background-color: var(--bg);
	border-radius: 1.5rem;
	padding: rem(24px);
	@include mixins.borders-effect;
}

.imgContainer {
	width: 100%;
	aspect-ratio: 3/1;
	overflow: hidden;
	cursor: pointer;
	border-radius: rem(12px);
	background: var(--text-normal);

	& > img {
		width: 100%;
		height: 100%;
		border-radius: 12px;
		object-fit: cover;
		object-position: center;
	}
}

.descriptionContainer {
	display: flex;
	flex-direction: column;
	gap: rem(16px);

	.title {
		color: var(--text-normal);
		font-size: rem(16px);
		font-weight: 600;
		margin: 0;
		word-break: break-all;
		cursor: pointer;
	}
	.description {
		width: 100%;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		white-space: pre-wrap;
		word-break: break-word;
		color: var(--text-normal);
		font-size: rem(13px);
		font-weight: 500;
		line-height: rem(16px);
		margin: 0;
	}
}

.iconContainer {
	display: flex;
	flex-direction: row;
	gap: rem(16px);
}

.btnContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: auto;
	color: var(--text-normal);
	cursor: pointer;

	@include max-sm {
		flex-wrap: wrap;
		justify-content: center;
		gap: rem(16px);

		button {
			width: 100% !important;
		}
	}
}
