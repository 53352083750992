@import 'functions';
@import 'breakpoints.module.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: rem(16px);
	width: rem(479px);
	min-height: rem(428px);
	max-height: rem(628px);

	.title {
		display: flex;
		justify-content: space-between;

		& h4 {
			font-size: rem(16px);
			font-weight: 600;
			color: var(--text-primary);
		}
	}

	.menuBar {
		display: flex;
		font-size: rem(14px);
		cursor: pointer;

		.menuItem {
			width: rem(64px);
			height: rem(32px);
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--text-disabled);
		}
		.selected {
			color: var(--text-subtle);
			border-bottom: rem(1.5px) solid var(--text-primary);
		}
	}

	.divider {
		width: calc(100% + rem(48px));
		margin: 0 rem(24px) 0 rem(-24px);
		border-bottom: 1px solid var(--border-popup, #333d43);
		background-color: var(--border-popup);
	}

	.notificationsList {
		display: flex;
		flex-direction: column;
		width: calc(100% + rem(24px));
		overflow-y: scroll;

		.notificationItem {
			font-size: rem(14px);
			font-weight: 600;
			padding: rem(12px) 0;
			border-bottom: 1px solid var(--border-popup-inside);
			margin-right: rem(24px);
			justify-content: space-between;
			display: flex;
			flex-direction: row;

			&:first-child {
				padding: 0 0 rem(12px) 0;
			}

			&:last-child {
				border-bottom: none !important;
				padding-bottom: 0 !important;
			}

			&.unread {
				.notificationTitle a {
					color: var(--text-subtle);
				}
			}

			.notificationTitle {
				white-space: nowrap;
				overflow: hidden;
				display: flex;
				width: 100%;
				gap: rem(8px);
				a {
					color: var(--text--normal);
					text-overflow: ellipsis;
					overflow: hidden;
					&:hover {
						color: var(--text-primary);
					}
				}
				small {
					flex: 0 auto;
					color: var(--text-disabled);
					align-self: center;
				}
			}

			.deleteIcon {
				color: var(--text-disabled);
				cursor: pointer;
				margin-left: auto;

				&:hover {
					color: var(--add-red);
				}
			}

			.notificationDate {
				color: var(--text-normal);
			}
		}
	}

	@include max-sm {
		width: 100%;
	}
}

.footer {
	display: flex;
	flex-direction: column;
	margin-top: auto;

	.checkAsRead {
		display: flex;
		align-items: center;
		gap: 6px;
		color: var(--text-primary);
		font-size: rem(14px);
		font-weight: 600;
		cursor: pointer;
	}

	.deleteAllItems {
		display: flex;
		align-items: center;
		gap: 6px;
		color: var(--text-normal);
		font-size: rem(14px);
		font-weight: 600;
		cursor: pointer;

		&:hover {
			color: var(--add-red);
		}
	}
}
