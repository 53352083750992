@use 'mixins';
@import 'functions';

.newCard-container {
	display: flex;
	flex-direction: column;
	max-height: rem(380px);
	background-color: var(--bg);
	border-radius: 1.5rem;
	padding: 1.5rem;
	@include mixins.borders-effect;

	.newCardImage-container {
		width: 100%;
		aspect-ratio: 3/1;
		overflow: hidden;
		border-radius: 12px;
		background: var(--bg-light);

		.newCardImage {
			width: 100%;
			height: 100%;
			border-radius: 12px;
			object-fit: cover;
			object-position: center;
		}
	}
	.description-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 1.5rem 0;
		.description-title {
			color: var(--text-normal);
			font-size: rem(16px);
			margin: 0;
			word-break: break-word;
		}
		.description {
			width: 100%;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			white-space: pre-wrap;
			word-break: break-word;
			color: var(--text-normal);
			font-size: rem(13px);
			font-weight: 600;
			line-height: rem(16px);
			margin: 0;
		}
	}
	.btn-container {
		display: flex;
		margin-top: auto;
		width: 100%;
		.btn-card {
			margin-top: auto;
			font-size: rem(18px);
			font-weight: 600;
			color: var(--text-normal);
			background: transparent;
			border: rem(1px) solid var(--text-normal);
			border-radius: 1.5rem;
			height: rem(40px);
			width: rem(140px);
			cursor: pointer;
			&:hover {
				background: var(--bg-dark, #1c2225);
				color: var(--text-subtitle);
			}
		}
	}
}
