@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';

.card-container {
	flex-direction: column;
	background-color: var(--bg);
	border-radius: 1.5rem;
	padding: rem(24px);
	display: flex;
	flex-grow: 1;
	@include mixins.borders-effect;
	height: 100%;

	// fix for apps card
	& > *,
	& > * > * {
		height: 100%;
	}

	.card-content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.card-body {
		flex-grow: 1;
	}

	.title-container {
		display: flex;
		align-items: center;
		gap: rem(12px);
		padding-bottom: rem(17px);
		.icon-container {
			color: var(--text-normal);
			width: rem(22px);
			height: rem(22px);
			display: flex;
			> img,
			> svg {
				border-radius: 10%;
				width: rem(22px);
				height: rem(22px);
				object-fit: cover;
				object-position: center;
			}
		}
		.title {
			color: var(--text-subtle);
			font-size: rem(16px);
			font-weight: 600;
			margin: 0;
			word-break: break-all;
		}
	}

	.description-container {
		max-height: rem(96px);
		margin-bottom: rem(16px);
		.description {
			width: 100%;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
			word-break: break-word;
			color: var(--text-normal);
			font-size: rem(13px);
			font-weight: 600;
			line-height: rem(16px);
			margin: 0;
		}
	}

	.btn-container {
		display: flex;
		margin-top: auto;
		gap: rem(16px);
		padding-top: 1rem;

		@include max-sm {
			flex-wrap: wrap;
			justify-content: center;
			button {
				width: 100% !important;
			}
		}
	}
}
