@import 'functions';

.preloader {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--bg);
	width: 100vw;
	height: 100vh;
	& > .ant-spin-lg > .ant-spin-dot.ant-spin-dot-spin {
		font-size: rem(128px) !important;
		& > .ant-spin-dot-item {
			width: rem(96px) !important;
			height: rem(96px) !important;
		}
	}
}
