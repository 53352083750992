@import './assets/styles/breakpoints.module.scss';
@import './components/mc/mc.scss';

* {
	font-family: 'Inter', sans-serif;
	box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
body,
p {
	margin: 0;
	font-weight: 600;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root {
	--primary: #0daec9;
	--primary-dark: #068298;
	--surface: #181d1f;
	--text-normal: #89969f;
	--text-subtle: #e7e7e7;
	--text-subtitle: #e7e7e7;
	--text-primary: #01b2d0;
	--text-disabled: #4b5559;
	--logo: #13adc8;
	--bg: #252b2e;
	--bg-dark: #181d1f;
	--bg-darker: #1c2225;
	--bg-light: #33393c;
	--bg-hover: #203f46;
	--bg-active: #0b3842;
	--bg-40: rgba(0, 0, 0, 0.4);
	--bg-60: rgba(0, 0, 0, 0.6);
	--contrast-color: #000;
	--border-install-update: #1f2425;
	--border-popup: #33393c;
	--border-popup-inside: #333d43;
	--border-sidebar: #252b2e;
	--border-active: #162e33;
	--add-green: #4bf083;
	--add-blue: #396df0;
	--add-red: #f04b4b;
	--add-orange: #e88224;
	--add-yellow: #fdfd5e;
	--button-text: var(--text-subtle);
	--button-bg: var(--primary-dark);
	--button-secondary: #6c7b84;
	--element-disabled: #31383b;
	--max-width: #{$xlBreakpoint};
	--search-width: 66.9375rem;
	--search-width-close: 17.0625rem;
}

.lightMode {
	--primary: #0daec9;
	--primary-dark: #068298;
	--surface: #f7f7f7;
	--text-normal: #424444;
	--text-subtle: #222528;
	--text-subtitle: hsl(210, 8%, 15%);
	--text-primary: #01b2d0;
	--text-disabled: #abbbc1;
	--logo: #13adc8;
	--bg: #ffffff;
	--bg-dark: #f9f9f9;
	--bg-darker: #ececed;
	--bg-light: #ffffff;
	--bg-hover: #203f46;
	--bg-active: var(--primary-dark);
	--bg-40: rgba(0, 0, 0, 0.4);
	--bg-60: rgb(194 192 192 / 95%);
	--contrast-color: #fff;
	--border-install-update: hsl(190, 9%, 86%); // NOT IN FIGMA
	--border-popup: #d4cccc;
	--border-popup-inside: #a59f9f69;
	--border-sidebar: #252b2e;
	--border-active: #162e33;
	--add-green: #4bf083;
	--add-blue: #396df0;
	--add-red: #f04b4b;
	--add-orange: #e88224;
	--add-yellow: #919c00;
	--button-text: #ffffff;
	--button-bg: #0daec9;
	--button-secondary: #6c7b84;
	--element-disabled: #c5cbce;
	--max-width: #{$xlBreakpoint};
}

@import url('./assets/styles/_antd-overrides.scss');
