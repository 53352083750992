.blockContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.blockHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.blockHeaderButtons {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
}

.licenseContainer {
	width: 100%;
	border: 1px solid var(--border-popup-inside);
	border-radius: 2rem;
	overflow: hidden;
	margin: 1rem 0;

	& > :not(:last-child) {
		border-bottom: 1px solid var(--border-popup-inside);
	}
}

.card {
	align-items: center;
	border: none;
	width: 100%;
	display: flex;
	justify-content: flex-start;
}

.cardInner {
	width: 100%;
	align-items: center;
	display: flex;
	flex-direction: row !important;
	gap: 2rem;
}

.disabledCard {
	cursor: not-allowed;
	background-color: rgba(255, 0, 0, 0.1);
}

.columnCategory {
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	overflow-x: auto;
	overscroll-behavior-x: auto;
	text-wrap: nowrap;
}

.moduleSelector {
	border-color: var(--buttons-secondary);
	height: 39px;
}

.statusIcon {
	width: 12px;
	height: 12px;
	display: inline-flex;
	border-radius: 50%;
}

.empty {
	text-align: center;
	color: var(--text-disabled);
}

.licenseInfoField {
	flex: 1;
}

.cardButton {
	cursor: pointer;
	display: inline-flex;
	height: 24px;
	width: 24px;
	justify-content: center;
	align-items: center;

	&:hover {
		color: var(--text-normal);
	}
}

.optionLabel {
	width: 100%;
	color: var(--text-normal);
}

.failedToLoadWarning {
	width: 100%;
	text-align: center;
	color: var(--add-red);
	margin-top: 2rem;
}
