@use 'mixins';
@import 'functions';

.container {
	display: flex;
	flex-direction: column;
	align-items: center !important;
	border-top: 1px solid var(--primary);
	background-color: var(--bg) !important;
	& > div {
		display: flex;
		align-items: center;
	}
}

.button {
	font-weight: 800;
	color: white !important;
	border: none !important;
	border-radius: 50px !important;
	color: var(--button-text) !important;
	cursor: pointer !important;
	display: flex !important;
	align-items: center !important;
	font-size: 1.125rem !important;
	font-weight: 600 !important;
	gap: 0.5rem !important;
	height: 2.875rem !important;
	justify-content: center !important;
	padding: 0.5rem 2rem !important;
	&:hover {
		opacity: 0.8 !important;
	}
}

.acceptButton {
	background-color: var(--button-bg) !important;
}

.declineButton {
	background-color: var(--add-red) !important;
}

.content {
	max-width: 750px;
	flex-grow: unset !important;
	flex-basis: auto !important;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.contentTitle {
	color: var(--text-normal);
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
}

.contentParagraph {
	color: var(--text-normal);
	font-size: rem(14px);
	font-weight: 400;
}

.policyTable {
	border: 1px solid var(--text-normal);
	width: 100%;

	th {
		border: 1px solid var(--text-normal);

		b {
			color: var(--text-normal) !important;
		}
	}

	td {
		border: 1px solid var(--text-normal);
	}

	td,
	th {
		padding: 10px;
	}
}
