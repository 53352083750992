@use 'mixins';
@import 'functions';
@import 'breakpoints.module.scss';

.appsSection {
	margin-top: rem(32px);
}
.titleRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: rem(16px);
	.title {
		flex-grow: 1;
		font-weight: 600;
		font-size: rem(20px);
		margin: 0;
		color: var(--text-normal);
	}
}

.allAppsContainer {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: rem(10px);
	gap: 16px;
	width: 100%;
}
.cardWrapper {
	width: 100%;
	@include min-md {
		width: calc((100% - rem(32px)) / 3);
		display: flex;
	}
	.container {
		@include mixins.borders-effect;
	}
}

.paginationContainer {
	display: flex;
	justify-content: center;
	margin-top: rem(24px);
}
