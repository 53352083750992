@import 'functions';

.supportSidebar {
	position: relative;
	border-right: 2px solid var(--bg);

	.menuCategoryTitle {
		line-height: initial;
		font-size: 1rem;
		color: var(--text-normal);
		text-overflow: ellipsis;
		overflow: hidden;
		padding-block: 0.5rem;
	}

	:global {
		.ant-menu-item-group-title {
			padding-left: 0 !important;
			margin-top: rem(20px);
			margin-left: 0 !important;
			text-align: left !important;
		}

		ul.ant-menu {
			display: flex;
			flex-direction: column;
			color: var(--text-normal);

			li.ant-menu-item {
				margin-block: 0.25rem;
				display: flex;
				align-items: center;

				.ant-menu-item-icon + span {
					margin-left: 1rem;
				}
			}
		}
	}
}

.supportSidebarToggleButton {
	cursor: pointer;
	padding: 0.5rem;
	margin-left: -0.5rem;
	transition: all 0.3s;
	width: fit-content;
	border-radius: 0.25rem;
	display: flex;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}
}
