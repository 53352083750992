body {
	.ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
		background: var(--bg-active);
		color: var(--button-text);
		.ant-collapse-expand-icon svg path {
			stroke: var(--button-text);
		}
	}

	.ant-collapse-item.ant-collapse-item-active .ant-collapse-header p {
		color: var(--text-subtle);
	}

	span.anticon.ant-menu-item-icon {
		min-width: inherit !important;
	}

	.ant-spin-nested-loading {
		width: 100%;
	}

	.sidebar li.ant-menu-item {
		overflow: visible;
		display: flex !important;
		align-items: center;
	}

	.ant-spin-blur {
		opacity: 0.7 !important;

		&::after {
			opacity: 0;
		}
	}
	.ant-card {
		.ant-card-body {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
		.ant-card-meta-description {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			max-height: 6em;
		}
	}

	.ant-input-lg,
	.ant-select-item-option-content {
		font-weight: 600;
	}

	.ant-select-dropdown
		.ant-select-item-option-active:not(.ant-select-item-option-disabled)
		> div
		> div {
		color: var(--text-primary);
	}

	.ant-select-dropdown {
		border: 0.0625rem solid var(--border-popup);
		padding: 0;
		.ant-select-item:hover {
			color: var(--text-subtitle);
		}
	}

	.ant-select.ant-select-multiple.ant-select-show-search {
		.ant-select-selector {
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 0.5rem;
			min-height: 3rem;

			.ant-select-selection-overflow-item .ant-tag {
				height: 2rem;
				margin: 0.4rem;
				margin-right: 0.5rem;
				margin-left: 0;
			}

			.ant-select-selection-overflow-item-suffix {
				height: 2rem;
				margin-left: 0.25rem;
			}

			.ant-select-selection-placeholder,
			.ant-select-selection-overflow-item-suffix:first-child {
				margin-left: 0.75rem;
			}
		}

		.ant-select-item-option-content {
			align-items: center;
			display: flex;
		}
	}

	.ant-popover.primary-popover {
		.ant-popover-inner,
		.ant-popover-arrow:before {
			background-color: var(--primary-dark);
		}
		.ant-popover-inner-content,
		.ant-popover-title {
			color: var(--button-text);
		}
	}
	.ant-modal .ant-modal-content {
		background-color: var(--bg);
		border: 1px solid var(--bg-light);
		box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.6);
		border-radius: 1.5rem;

		.ant-modal-confirm-btns {
			margin-top: 24px;
			button {
				border-radius: 3.125rem;
				padding: 0.5rem 2rem;
			}
		}
	}
	// tour
	.ant-tour {
		max-width: 100%;
	}
	.ant-tour-title {
		font-size: 1.25rem !important;
		color: var(--text-normal) !important;
	}

	.ant-tour-next-btn,
	.ant-tour-prev-btn {
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		font-family: Inter;
		font-size: 1.125rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		border-radius: 3.125rem;
		transition-duration: 0.1s;
		transition-timing-function: ease-in-out;
		transition-property: background, border-color, color;
		-webkit-user-select: none;
		user-select: none;
		padding: 0.5rem 2rem !important;
		height: auto;
	}

	.ant-tour-next-btn {
		border: 0.1rem solid var(--button-bg, #068298);
		background: var(--button-bg);
		color: var(--button-text);
	}

	.ant-tour-prev-btn {
		border: 0.1rem solid var(--buttons-secondary, #6c7b84);
		background: transparent;
		color: var(--text-normal);
	}
	.ant-tour-footer {
		margin-top: 0.5rem;
	}
	.ant-checkbox-wrapper {
		display: flex;
		align-items: center;
	}
	.ant-steps-item {
		cursor: pointer;
		&:hover {
			opacity: 0.8;
			.ant-steps-item-title {
				color: var(--text-subtitle) !important;
			}
		}
		&.ant-steps-item-active {
			.ant-steps-item-title {
				color: var(--primary) !important;
			}
		}
	}
	@media (max-width: 576px) {
		.ant-steps.ant-steps-vertical {
			display: none;
		}
	}
	.ant-image-mask {
		border-radius: 0.75rem;
	}
	.scrollable {
		height: 12.5rem;
		overflow-y: auto;
	}

	#sentry-feedback {
		--actor-background: var(--bg);
		--actor-color: var(--text-subtitle);
		--button-color: var(--text-subtitle);
		--dialog-color: var(--text-subtitle);
		--background: var(--bg-dark);
		--button-primary-background: var(--button-bg);
		--actor-hover-background: var(--bg-active);
		--button-hover-background: var(--button-bg);
		--accent-background: var(--button-bg);
		--border: 1px solid var(--border-popup);
		--dialog-background: var(--bg-dark);
		--dialog-border: 1px solid var(--primary-dark);
		--dialog-box-shadow: 0px 2px 30px var(--bg-60);
		--dialog-close-hover-border: 1px solid var(--border-popup);
	}

	.groupContainer {
		display: flex;
		flex-direction: column;
		background-color: var(--bg);
		padding: 1rem;
		gap: 2rem;
		border-radius: 1.5rem;
		.section-title {
			color: var(--text-subtitle) !important;
		}
	}

	// new carousel adjustments
	.ant-carousel {
		.slick-slide {
			padding: 0 0.5rem;
		}

		.slick-list {
			margin: 0 -0.5rem;
		}

		.slick-arrow {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;
			color: white;
			width: 30px;
			height: 30px;
			background-color: var(--bg);
			border-radius: 0.5rem;
			border: 1px solid var(--border-popup-inside);
			cursor: pointer;
			text-align: center;
			align-content: center;

			&:hover {
				opacity: 0.8;
			}

			&.slick-disabled {
				opacity: 0.3;
				cursor: not-allowed;
				pointer-events: none;
			}
		}

		.slick-prev {
			left: -15px;
		}

		.slick-next {
			right: -15px;
		}

		// Mobile adjustments for carousel
		@media screen and (max-width: 768px) {
			.slick-arrow {
				width: 25px;
				height: 25px;
			}

			.slick-prev {
				left: -18px !important;
			}

			.slick-next {
				right: -18px !important;
			}
		}
	}

	.ant-tooltip {
		.ant-tooltip-inner {
			background-color: var(--bg-hover);
			color: var(--button-text);
			border-radius: 0.5rem;
			padding: 0.5rem 1rem;
			font-size: 0.875rem;
		}

		.ant-tooltip-arrow {
			&::before {
				background-color: var(--bg-hover);
			}
		}
	}
	.ant-select-arrow {
		opacity: 1 !important;
	}
}
