@import 'functions';
@import 'breakpoints.module.scss';
@import 'vars';

.popoverSettings-content {
	position: relative;
	width: $profile-addon-width;
	color: var(--text-normal);

	.popoverSettings-avatar {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		border-bottom: 1px solid var(--border-popup-inside);
	}

	.popoverSettings-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding-top: 1.5rem;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: rem(12px);
		li {
			display: flex;
			gap: rem(8px);
			width: 100%;
			font-size: rem(14px);
		}
	}
	p {
		font-size: rem(14px);
	}
	.closePopover {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}
	@include max-sm {
		width: 92%;
		margin: 0 auto;
	}
}
.AboutPopover {
	.about-logo-wrapper {
		margin-bottom: rem(16px);
		gap: rem(8px);
		h5.logo-text {
			color: var(--text-subtitle, #e7e7e7);
			font-family: Inter;
			font-size: 14px;
		}
		.version-text {
			color: var(--text-normal, #89969f);
			/* Small */
			font-family: Inter;
			font-size: 10px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
}
.PreferencesPopover {
	.preferences-wrapper {
		h4 {
			color: var(--text-subtitle, var(--text-subtitle, #e7e7e7));
			line-height: normal;
		}

		.mc-switch {
			display: flex;
			align-items: center;
			gap: rem(8px);
			margin-top: 1rem;
		}

		.mc-button {
			margin: rem(32px) auto 0;
		}
	}
}
