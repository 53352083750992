@use 'mixins';
@import 'functions';

.inputFieldWrapper {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: rem(8px);
}

.inputFieldLabel {
	font-size: rem(14px);
	font-weight: 600;
	color: var(--text-normal);
}

.multiSelectField {
	width: 100%;
	min-height: rem(48px);
	display: flex;
	align-items: center;
	padding-left: rem(6px);
	border: rem(1.5px) solid var(--border-popup);
	border-radius: 1.5rem;
	font-size: rem(16px);
	font-weight: 600;
	color: var(--text-normal);
	:global {
		.ant-select-clear {
			color: var(--text-normal) !important;
			background: var(--bg);
			margin-right: rem(10px);
			position: absolute;
			top: rem(15px);
			svg {
				width: rem(19px) !important;
				height: rem(19px) !important;
			}
		}
		.ant-select-selection-placeholder {
			width: 100%;
			display: flex;
			align-items: center;
			font-size: rem(16px);
			color: var(--text-normal);
		}
		.ant-select-dropdown {
			left: 0 !important;
			background-color: var(--bg);
			border: rem(1px) solid var(--border-popup);
			border-radius: 16px;
			padding: 0;
			box-shadow: 0 rem(2px) rem(30px) var(--bg-60);
		}
		.ant-select-selector {
			width: 100%;
			min-height: rem(44px);
			.ant-select-selection-item {
				color: var(--text-normal);
				font-size: rem(16px);
				font-weight: 600;
				display: flex;
				align-items: center;
			}
		}
		.ant-select-arrow {
			color: var(--text-normal);
		}
		.ant-select-item-option-content {
			color: var(--text-normal);
			font-size: rem(16px);
			font-weight: 600;
		}
		div.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
			background-color: transparent;
			border-radius: 0;
			.ant-select-item-option-content {
				color: var(--text-primary);
				display: flex;
				justify-content: space-between;
				&::after {
					content: url('../icons/checkIcon.svg');
				}
			}
		}
		div.ant-select-item.ant-select-item-option:hover {
			background-color: var(--bg-hover);
			> .ant-select-item-option-content {
				color: var(--primary);
			}
		}
		span.anticon.anticon-check {
			visibility: hidden;
		}
		.ant-select-selection-search-input {
			font-size: rem(16px);
			font-weight: 600;

			color: var(--text-normal) !important;
			display: flex;
			align-items: center;
			height: 100% !important;
		}
		.ant-select-selection-placeholder {
			color: var(--text-disabled) !important;
		}
	}
}

.dropdownItem {
	display: flex;
	align-items: center;
	gap: rem(10px);

	.dropdownItemStatus {
		margin-left: auto;
		text-align: left;
		min-width: rem(80px);
		display: flex;
		align-items: center;
		gap: rem(8px);
	}
}

.dropdownItemCompany {
	opacity: 0.6;
}

.labelIconName {
	display: flex;
	align-items: center;
	gap: rem(10px);
}

.errorMessage {
	width: 100%;
	height: rem(24px);
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	color: var(--add-red);
	font-size: rem(14px);
	font-weight: 600;
}
